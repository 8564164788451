import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStores } from '../../../../stores/store';
import { Spinner } from '../../../../components/spinners/spinner';
import { useHistory } from "react-router-dom";
import { FaArrowLeft } from 'react-icons/fa';
import ErrorMessage from '../../../../components/other/ErrorMessage';
import AdminUserEditPage from './adminUser/adminUserEditPage';
import InstitutionUserEditPage from './institutionUser/institutionUserEditPage';

const UserEdit = observer(() => {
	const { app } = useStores();
	const { t } = useTranslation();
	const history = useHistory();
	let { userId } = useParams();
	const [data, setData] = useState(null);
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [fetchingError, setFetchingError] = useState(null);

	useEffect(() => {
		if (userId === undefined)
			return;

		setIsFetchingData(true);
		app.restStore.getAdminUser(userId).then((res) => {
			setData(res.data.data);
		}).catch((error) => {
			setFetchingError(error);
		}).finally(() => {
			setIsFetchingData(false);			
		});
	}, [userId]);

	return (
		<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-6 px-2">
			<div className="w-full">
				<span onClick={history.goBack} className="text-sm font-bold mb-2 flex flex-row gap-1 text-stone-400 align-middle cursor-pointer"><FaArrowLeft />{t('users.backToList')}</span>
				<h1 className="text-4xl">{t('userData.details')}</h1>
				{ isFetchingData && <Spinner /> }
				{ fetchingError && <ErrorMessage text={fetchingError} />}
				{ !isFetchingData && !fetchingError && data.role === "admin" && ( <AdminUserEditPage data={data} /> )}
				{ !isFetchingData && !fetchingError && data.role !== "admin" && ( <InstitutionUserEditPage data={data} /> )}
			</div>
		</div>
	);
});

export default UserEdit;