import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Link } from 'react-router-dom';
import { useStores } from "../../../stores/store";
import DTable from "../../../components/tables/DTable"
import { BaseModal } from '../../../components/modals/baseModal';
import type { User } from "../../../models/user";

import { createColumnHelper } from '@tanstack/react-table'
import { Spinner } from "../../../components/spinners/spinner";
import ErrorMessage from "../../../components/other/ErrorMessage";
import { CSVLink } from "react-csv";

const AdminUserListPage = observer(() => {
	const { app } = useStores();
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [fetchingError, setFetchingError] = useState(null);
	const [deleteAsk, setDeleteAsk] = useState(null);

	const columnHelper = createColumnHelper<User>()

	const columns = [
		columnHelper.accessor('first_name', {
			header: () => <span>{t('userData.firstName')}</span>,
			cell: info => info.getValue(),
			footer: info => info.column.id,
		}),
		columnHelper.accessor(row => row.last_name, {
			id: 'last_name',
			header: () => <span>{t('userData.lastName')}</span>,
			cell: info => info.getValue(),
			footer: info => info.column.id,
		}),
		columnHelper.accessor('role', {
			header: () => <span>{t('userData.role')}</span>,
			cell: info => info.renderValue(),
			footer: info => info.column.id,
		}),
		columnHelper.accessor('email', {
			header: () => <span>{t('userData.email')}</span>,
			footer: info => info.column.id,
		}),
	]

	const refresh = () => {
		setIsFetchingData(true);
		app.restStore.getAdminUsers().then((res) => {
			console.log(res.data.data.items)
			setData(res.data.data.items);
		}).catch((error) => {
			setFetchingError(error);
		}).finally(() => {
			setIsFetchingData(false);			
		});
	}

	useEffect(() => {
		refresh();
	}, []);

	const deleteItem = (id: string) => {
		setDeleteAsk(id);
	}

	const doDeleteItem = (id: string) => {
		app.restStore.deleteAdminUser(id).then((res) => {
			refresh();
			setDeleteAsk(null);
		}).catch((error) => {
		}).finally(() => {
		});
	}

	const filterCSV = (data) => {
		return data.map(({last_name, first_name, nickname, email, role, address}) => ({last_name, first_name, nickname, email, role, address}));
	}

	const deleteModal = (
		<BaseModal
			isOpen={deleteAsk != null}
			onClose={() => {setDeleteAsk(null)}}
			onCta={() => { doDeleteItem(deleteAsk) }}
			ctaText={t('misc.yes')}
			closeText={t('misc.no')}
			title={t('userActions.delete')}
		>
			<div>
				<div className='font-semibold text-4xl text-black mb-4'>{t('userActions.delete')}</div>
				<div className='px-4 sm:px-6 pb-4 sm:pb-6 text-black'>{t('userActions.confirmDelete')}</div>
			</div>
		</BaseModal>
	)

	return (
		<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-6 px-2">
			<div className="w-full md:w-11/12">
				<h1 className="text-4xl mb-4">{t('users.list')}</h1>
				{ isFetchingData && <Spinner /> }
				{ !isFetchingData && !fetchingError && (
					<div>
						<Link className='cursor-pointer p-1 bg-secondary text-white rounded-full float-right mb-2 ml-1' to={{ pathname: `new`}}>{t('userActions.addNew')}</Link>
						<CSVLink filename={"users-export.csv"} className='cursor-pointer p-1 bg-secondary text-white rounded-full float-right mb-2 ml-1' data={filterCSV(data)}>CSV</CSVLink>
						<DTable columns={columns} rows={data} actionsEnabled={true} delete={deleteItem} /> 
					</div>
				)}
				{ fetchingError && <ErrorMessage text={fetchingError} />}
			</div>

			{ deleteModal }
		</div>
    );
});

export default AdminUserListPage;