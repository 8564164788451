import React from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useStores } from "../../stores/store";

const InstitutionNavbar: React.FC = () => {
	const { t } = useTranslation();
	const { auth } = useStores();

	return (
		<div className="bg-light border-right py-5 px-6 w-64 h-screen flex flex-col justify-between">

			<div>
				<div className="mb-8">
					<img alt="logo" src="/images/logo_light.png" />
				</div>

				<div className="text-white mb-4 align-middle">
					<Link to={{ pathname: `/institution/dashboard/`}}>
						<img alt="dashboard" className="inline align-middle mr-2" src="/icons/dashboard.png" />{t('dashboard.dashboard')}
					</Link>
				</div>

				<div className="text-white mb-4 align-middle">
					<Link to={{ pathname: `/institution/profile/`}}>
						<img alt="profile" className="inline align-middle mr-2" src="/icons/userwhite.png" />{t('userData.myProfile')}
					</Link>
				</div>

				<div className="text-white mb-4 align-middle">
					<Link to={{ pathname: `/institution/activities/`}}>
						<img  alt="institution" className="inline align-middle mr-2" src="/icons/entidashboard.png" />{t('activities.activities')}
					</Link>
				</div>
				
				<div className="text-white mb-4 align-middle">
					<Link to={{ pathname: `/institution/donors/`}}>
						<img  alt="donors" className="inline align-middle mr-2" src="/icons/userwhite.png" />{t('donors.donors')}
					</Link>
				</div>
			</div>

			<div>
				<div>
					<div className="text-white mb-4 align-middle">
						<img  alt="profile" className="inline align-middle mr-2" src="/icons/userwhite.png" />{auth.profile?.name}
					</div>
				</div>
				<div>
					<div className="text-white mb-4 align-middle cursor-pointer" onClick={auth.logout}>
						<img alt="logout" className="inline align-middle mr-2" src="/icons/userwhite.png" />{t('userActions.logout')}
					</div>
				</div>
			</div>

		</div>
	);
};

export default InstitutionNavbar;