import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
	it: {
		translation: {
			'lostPassword': 'Password dimenticata?',
			'signupQuestion': 'Sei un ente e non hai un account?',
			'loginQuestion': 'Hai già un account?',
			'alreadyRegisteredQuestion': 'Hai già un account?',
			'actions': 'Azioni',
			'details': 'Dettagli',
			'errors': {
				'passwordsNotMatch': 'Le password non corrispondono',
				'authError': 'Username o password errati',
				'loadingData': 'Errore durante il caricamento',
				'nicknameRequired': 'Il nickname è obbligatorio',
				'insertValidEmail': 'Inserisci una mail valida',
				'insertValidCode': 'Inserisci un codice valido',
				'resultRequired': 'Un risultato è richiesto',
				'typeRequired': 'Un tipo è richiesto',
				'fileIsTooBig': 'Il file è troppo grande. Limite: 10MB',
				'descriptionRequired': 'La descrizione è obbligatoria',
				'payoffRequired': 'Il payoff è obbligatorio',
				'topicRequired': 'Topic è obbligatorio',
				'ninRequired': 'CF/PIVA è obbligatorio',
			},
			'misc': {
				'yes': 'Si',
				'no': 'No',
				'images': 'Immagini (Max 10 MB)',
				'save': 'Salva',
				'cancel': 'Annulla',
				'year': 'Anno',
				'next': 'Avanti',
				'logo': 'Logo (Max 10MB)'
			},
			'userActions': {
				'login': 'Accedi',
				'signup': 'Registrati',
				'logout': 'Logout',
				'delete': 'Elimina',
				'confirmDelete': 'Vuoi veramente eliminare questo elemento?',
				'addImage': 'Aggiungi immagine',
				'modifyPassword': 'Modifica password',
				'stripeOnboard': 'Stripe onboard',
				'addNew': 'Aggiungi',
				'addLogo': 'Aggiungi logo',
				'addDocument': 'Aggiungi documento',
				'selectFile': 'Seleziona file',
			},
			'userData': {
				'details': 'Dettaglio utente',
				'profile': 'Profilo',
				'myProfile': 'La mia scheda',
				'name': "Nome",
				'firstName': 'Nome',
				'lastName': 'Cognome',
				'nickname': "Nickname",
				'nin': 'CF/Partita IVA',
				'subscriptionDate': 'Data iscrizione',
				'userBadge': 'Badge utente',
				'zipCode': 'CAP',
				'city': 'Città',
				'province': 'Provincia',
				'region': 'Zona',
				'topic': 'Topic',
				'payoff': 'Payoff',
				'description': 'Descrizione',
				'role': 'Ruolo',
				'email': 'E-mail',
				'password': 'Password',
				'confirmPassword': 'Conferma password',
				'lengthValidation': 'Almeno 8 caratteri',
				'caseValidation': 'Almeno una maiscola e una minuscola',
				'numberValidation': 'Almeno un numero',
				'address': 'Indirizzo',
				'generalData': 'Informazioni generali',
				'docsArchive': 'Archivio documenti',
				'friends': 'Amici',
				'institutionsFollowed': 'Enti che segue',
				'donationHistory': 'Storico donazioni',
				'subscriptionActive': 'Abbonamento attivo',
				'subscriptionNotActive': 'Abbonamento non attivo',
				'subscriptionTrial': 'Subscription di prova scadrà il ',
			},
			'dashboard': {
				'dashboard': 'Dashboard',
				'supportsReceiver': 'Supporti ricevuti',
				'donationsPerInstitution': 'Donazioni ente',
				'donorNumber': 'Numero donatori',
				'donationsPerActivity': 'Donazioni campagne',
				'registeredUsers': 'Utenti registrati',
				'activeUsers': 'Utenti attivi',
				'totalUsers': 'Utenti totali',
				'newInstitutionsRegistered': 'Nuovi enti registrati',
				'totalInstitutions': 'Enti totali',
				'institutions': 'Enti',
				'donationsTrend': 'Andamento donazioni',
				'activeActivities': 'Campagne attive',
				'registeredToThePlatform': 'Registrati alla piattaforma',
				'donationsPerInstitutions': 'La sola somma delle donazioni ENTE',
				'donorNumberInstitution': 'Totale donatori ente e campagne',
				'donationsPerActivityInstitution': 'La sola somma delle donazioni delle campagne',
				'currentDay': 'Nel giorno corrente',
				'currentMonth': 'Nel mese corrente',
			},
			'users': {
				'users': 'Utenti',
				'list': 'Lista utenti',
				'add': 'Aggiungi utente',
				'edit': 'Modifica utente',
				'backToList': 'Torna alla lista utenti',
				'normalUser': 'User',
				'adminUser': 'Admin'
			},
			'institutions': {
				'addNew': 'Aggiungi ente',
				'institutions': 'Enti',
				'list': 'Lista enti',
				'detail': 'Dettaglio ente',
				'data': 'Dati ente',
				'backToList': 'Torna alla lista enti',
				'email': 'E-mail'
			},
			'activities': {
				'title': 'Titolo',
				'activities': 'Attività',
				'list': 'Lista attività',
				'topic': 'Topic',
				'description': 'Descrizione',
				'detail': 'Dettaglio campagna',
				'data': 'Dati campagna',
				'email': 'E-mail',
				'totalSupports': 'Supporti',
				'totalDonations': 'Donazioni',
				'result': 'Voglio un obiettivo?',
				'resultValue': 'Valore dell\'obiettivo',
				'type': 'Tipo',
				'backToList': 'Torna alla lista attività',
			},
			'documents': {
				'name': 'Nome',
				'download': 'Download',
				'uploadDate': 'Data caricamento',
			},
			'donors': {
				'donors': 'Donatori',
				'list': 'Lista donatori',
				'donationList': 'Lista donazioni',
				'detail': 'Dettaglio donatore',
				'data': 'Dati donatore',
				'quantity': 'Importo',
				'date': 'Data',
				'type': 'Tipologia',
				'total_donations': 'Donazioni',
				'backToList': 'Torna alla lista donatori',
			},
			'friends': {
				'accepted': 'Accettati',
				'pending': 'In attesa',
				'sent': 'Richiesta inviata'
			}
		},
	},
	en: {
		translation: {
			'lostPassword': 'Lost password?',
			'signupQuestion': 'Are you an instituition without account?',
			'loginQuestion': 'Are you already registered?',
			'alreadyRegisteredQuestion': 'Already registered?',
			'signup': 'Sign up',
			'actions': 'Azioni',
			'details': 'Details',
			'logout': 'Logout',
			'errors': {
				'authError': 'Username or password wrong',
				'passwordsNotMatch': 'Passwords don\'t match',
				'loadingData': 'Error while loading data',
				'nicknameRequired': 'Nickname is mandatory',
				'insertValidEmail': 'Insert a valid email',
				'insertValidCode': 'Insert a valid code',
				'resultRequired': 'A result is required',
				'typeRequired': 'A type is required',
				'fileIsTooBig': 'The file is too big. Limit: 10MB',
				'descriptionRequired': 'Description is required',
				'payoffRequired': 'Payoff is required',
				'topicRequired': 'Topic is required',
				'ninRequired': 'CF/PIVA is required',
			},
			'misc': {
				'yes': 'Yes',
				'no': 'No',
				'images': 'Images (Max 10MB)',
				'save': 'Save',
				'cancel': 'Cancel',
				'year': 'Year',
				'next': 'Next',
				'logo': 'Logo (Max 10MB)'
			},
			'userActions': {
				'login': 'Login',
				'signup': 'Sign up',
				'delete': 'Delete',
				'confirmDelete': 'Do you really want to delete this item?',
				'logout': 'Logout',
				'addImage': 'Add image',
				'modifyPassword': 'Modify password',
				'stripeOnboard': 'Stripe onboard',
				'addNew': 'Add new',
				'addLogo': 'Add logo',
				'addDocument': 'Add document',
				'selectFile': 'Select file',
			},
			'userData': {
				'details': 'User details',
				'profile': 'Profile',
				'myProfile': 'My profile',
				'name': "Name",
				'firstName': 'Name',
				'lastName': 'Surname',
				'nickname': "Nickname",
				'subscriptionDate': 'Subscription Date',
				'userBadge': 'User badge',
				'zipCode': 'ZIP code',
				'city': 'City',
				'province': 'Province',
				'nin': 'NIN/VAT number',
				'region': 'Region',
				'topic': 'Topic',
				'payoff': 'Payoff',
				'description': 'Description',
				'role': 'Role',
				'email': 'E-mail',
				'password': 'Password',
				'confirmPassword': 'Confirm password',
				'lengthValidation': 'At least 8 chars',
				'caseValidation': 'Uppercase and lowercase letters',
				'numberValidation': 'At least one number',
				'address': 'Address',
				'generalData': 'General data',
				'docsArchive': 'Documents archive',
				'friends': 'Friends',
				'institutionsFollowed': 'Institutions followed',
				'donationHistory': 'Donations history',
				'subscriptionActive': 'Subscription is active',
				'subscriptionNotActive': 'Subscription is not active',
				'subscriptionTrial': 'Trial subscription will expire ',
			},
			'dashboard': {
				'dashboard': 'Dashboard',
				'supportsReceived': 'Supports received',
				'donationsPerInstitution': 'Donations per institution',
				'donorNumber': 'Donors number',
				'donationsPerActivity': 'Donations per activity',
				'registeredUsers': 'Registered users',
				'activeUsers': 'Active users',
				'totalUsers': 'Total users',
				'newInstitutionsRegistered': 'New institution registered',
				'totalInstitutions': 'Total institutions',
				'institutions': 'Institutions',
				'donationsTrend': 'Donations trend',
				'activeActivities': 'Active activities',
				'registeredToThePlatform': 'Registered to the platform',
				'donationsPerInstitutions': 'Sum of institution donations',
				'donorNumberInstitution': 'Total number of donors for institutions and activities',
				'donationsPerActivityInstitution': 'Sum of activities\' donations',
				'currentDay': 'Current day',
				'currentMonth': 'Current month',
			},
			'users': {
				'users': 'Users',
				'list': 'User list',
				'add': 'Add user',
				'edit': 'Modify user',
				'backToList': 'Back to user list',
			},
			'institutions': {
				'addNew': 'add institution',
				'institutions': 'Institutions',
				'list': 'Institution list',
				'detail': 'Institution details',
				'data': 'Institution data',
				'backToList': 'Back to institution list',
				'email': 'E-mail'
			},
			'activities': {
				'activities': 'Activities',
				'title': 'Title',
				'topic': 'Topic',
				'list': 'Activity list',
				'detail': 'Activity details',
				'data': 'Activity data',
				'email': 'E-mail',
				'description': 'Descrizione',
				'totalSupports': 'Supports',
				'totalDonations': 'Donations',
				'result': 'Do i want to reach a result?',
				'resultValue': 'Result value',
				'type': 'Type',
				'backToList': 'Back to activity list',
			},
			'documents': {
				'name': 'Name',
				'download': 'Download',
				'uploadDate': 'Upload date',
			},
			'donors': {
				'donors': 'Donors',
				'list': 'Donor list',
				'donationList': 'Donation list',
				'detail': 'Donor details',
				'data': 'Donor data',
				'date': 'Date',
				'quantity': 'Amount',
				'type': 'Type',
				'total_donations': 'Donations',
				'backToList': 'Back to donors list',
			},
			'friends': {
				'accepted': 'Accepted',
				'pending': 'Pending',
				'sent': 'Sent'
			}
		},
	},
};

i18n
.use(initReactI18next) // passes i18n down to react-i18next
.init({
	resources,
	fallbackLng: "it",
	lng:
	window.localStorage.getItem("language") ||
	(navigator.language.indexOf("it") === -1 ? "en" : "it"),
	// you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
	// if you're using a language detector, do not define the lng option

	interpolation: {
	escapeValue: false, // react already safes from xss
	},
});

export default i18n;
