import { observer } from 'mobx-react-lite';
import { useEffect, useState, } from 'react';
import { useStores } from '../../../stores/store';
import { useTranslation } from "react-i18next";
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import DashboardCard from '../../../components/cards/dashboardCard';
import { Spinner } from '../../../components/spinners/spinner';
import ErrorMessage from '../../../components/other/ErrorMessage';
import { regions } from '../../../utils/utils';
import '../../../App.css';

const DashboardData = (props) => {
	const { t } = useTranslation();

	return (
		<>
			<div className='flex flex-row relative mb-4'>
				<DashboardCard title={t('dashboard.registeredUsers')} value={props.data.registered_users} description={t('dashboard.registeredToThePlatform')} type="value" />
				<DashboardCard title={t('dashboard.activeUsers')} value={props.data.active_users_today} description={t('dashboard.currentDay')} type="value" />
				<DashboardCard title={t('dashboard.totalUsers')} value={props.data.users_for_month} description="" type="chart" />
			</div>
			<div className='flex flex-row relative mb-4'>
				<DashboardCard title={t('dashboard.newInstitutionsRegistered')} value={props.data.registered_this_month_inst} description={t('dashboard.currentMonth')} type="value" />
				<DashboardCard title={t('dashboard.totalInstitutions')} value={props.data.registered_inst} description={t('dashboard.registeredToThePlatform')} type="value" />
				<DashboardCard title={t('dashboard.institutions')} value={props.data.registered_inst_for_region} description="" type="value" />
			</div>
			<div className='flex flex-row relative mb-4'>
				<DashboardCard title={t('dashboard.donationsTrend')} value={props.data.donations_for_month} description="" type="chart" />
				<DashboardCard title={t('dashboard.activeActivities')} value={props.data.active_campaign} description={t('dashboard.currentDay')} type="value" />
			</div>
		</>
	);
}

const AdminDashboard = observer(({ t }: any) => {
	const { app } = useStores();
	const [data, setData] = useState([]);
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [fetchingError, setFetchingError] = useState(null);
	const [region, setRegion] = useState(regions[0]);
	const [year, setYear] = useState(new Date().getFullYear());

	const years = [
		new Date().getFullYear()-1, new Date().getFullYear(), new Date().getFullYear()+1
	]

	useEffect(() => {
		setIsFetchingData(true);
		app.restStore.getAdminDashboardData(region, year).then((res) => {
			setData(res.data.data);
		}).catch((error) => {
			setFetchingError(error);
		}).finally(() => {
			setIsFetchingData(false);			
		});
    }, [region, year]);

	return (
		<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-4 px-6 justify-center">
			<div className="w-full">
				<h1 className="text-4xl mb-2">{t('dashboard.dashboard')}</h1>

			<div className='flex justify-start items-center mb-2'>
				<label className="mr-2">{t('userData.region')}:</label>
				<Select className='flex w-fit mr-2'
					value={{value: region, label: region}}
					options={regions.map((r) => ({'value': r, 'label': r}))}
					onChange={evt => setRegion(evt.value)}
				/>
				<label className="mr-2">{t('misc.year')}:</label>
				<Select className='flex w-fit'
					value={{value: year, label: year}}
					options={years.map((r) => ({'value': r, 'label': r}))}
					onChange={evt => setYear(evt.value)}
				/>
			</div>

			<div className='flex flex-col relative'>
				{ isFetchingData && <Spinner /> }
				{ !isFetchingData && !fetchingError && <DashboardData data={data} /> }
				{ fetchingError && <ErrorMessage text={fetchingError} />}
			</div>
			</div>
		</div>
	);
});

export const AdminDashboardPage = withTranslation()(AdminDashboard);
