import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../../stores/store";
import DTable from "../../../../../components/tables/DTable"
import type { Donation } from "../../../../../models/donation";

import { createColumnHelper } from '@tanstack/react-table'
import { Spinner } from "../../../../../components/spinners/spinner";
import ErrorMessage from "../../../../../components/other/ErrorMessage";
import { humanReadableDonationType } from "../../../../../utils/utils";

const InstitutionUserDonationHistoryTab = observer((props: any) => {
	const { app, auth } = useStores();
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [fetchingError, setFetchingError] = useState(null);

	const columnHelper = createColumnHelper<Donation>()

	const columns = [
		columnHelper.accessor(row => row.Institution, {
			id: 'title',
			header: () => <span>{t('userData.name')}</span>,
			cell: info => info.getValue() == null ? "" : info.getValue().name,
			footer: info => info.column.id,
		}),
		columnHelper.accessor('type', {
			header: () => <span>{t('donors.type')}</span>,
			cell: info => humanReadableDonationType(info.getValue()),
			footer: info => info.column.id,
		}),
		columnHelper.accessor('quantity', {
			header: () => <span>{t('donors.quantity')}</span>,
			cell: info => info.getValue() + " €",
			footer: info => info.column.id,
		}),
	]

	useEffect(() => {
		setIsFetchingData(true);
		app.restStore.getUserDonations(props.id).then((res) => {
			setData(res.data.data.donations);
		}).catch((error) => {
			setFetchingError(error);
		}).finally(() => {
			setIsFetchingData(false);			
		});
	}, []);

	return (
		<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-6 px-6">
			<div className="w-full">
				{ isFetchingData && <Spinner /> }
				{ !isFetchingData && !fetchingError && <DTable columns={columns} rows={data} actionsEnabled={false} /> }
				{ fetchingError && <ErrorMessage text={fetchingError} />}
			</div>
		</div>
    );
});

export default InstitutionUserDonationHistoryTab;
