import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { isBiggerThan, MAX_FILE_SIZE } from '../../utils/utils';

function Modal(props: {
	isOpen: boolean;
	onCta: Function;
	onClose: Function;
}) {
	const fileRef = useRef(null);
	const fileLoadRef = useRef(null);
	const [name, setName] = useState(null);
	const [fileName, setFileName] = useState("");
	const { t } = useTranslation();

	const fileUploadHandler = (event) => {
		event.preventDefault();

		var file = event.target.files[0];
		let fileSize = event.target.files[0].size;
		setFileName(event.target.files[0].name)

		if (isBiggerThan(fileSize, MAX_FILE_SIZE)) {
			return;
		}

		var reader = new FileReader();
		reader.onload = function(event) {
			fileRef.current = event.target.result;
		};
	  
		reader.readAsDataURL(file);
	};

	const onChangeTitle = (value) => {
		setName(value.target.value)
	}

	const loadDocument = (event) => {
		event.preventDefault();
		fileLoadRef.current.click();
	}

	return (
		<Transition.Root show={props.isOpen} as={Fragment}>
			<Dialog
				as='div'
				static
				style={{ zIndex: 1000 }}
				className='fixed inset-0 overflow-y-auto w-full h-screen'
				open={props.isOpen}
				//   @ts-ignore
				onClose={props.onClose}>
				<div className='flex w-full h-full items-center justify-center text-white text-sm'>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'>
							<Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-25 transition-opacity' />
					</Transition.Child>

					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
						enterTo='opacity-100 translate-y-0 sm:scale-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100 translate-y-0 sm:scale-100'
						leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
						<div className='bg-main relative w-10/12 sm:w-20 rounded-md'>
							<div className='px-1 sm:px-1'>
								<div className='sm:flex sm:items-center'>
									<div className='mt-1 w-full items-center flex flex-col gap-2'>
										<div className='font-semibold text-3xl text-black mb-4'>{t('userActions.addDocument')}</div>
										<input type="file" name="fileLogo"
											ref={fileLoadRef} accept="*/*" hidden
											onChange={fileUploadHandler}
										/>
										<input type="text"
											className='w-72 pl-2 py-1 mb-1 focus:border-secondary bg-white text-black'
											onChange={onChangeTitle}
										/>
										<label className="text-black">{fileName}</label>
										<button
											onClick={loadDocument}
											className='cursor-pointer btn bg-secondary text-white mb-2'
										>{t('userActions.selectFile')}</button>
									</div>
								</div>
							</div>
							<div className='px-4 sm:px-6 pb-2 sm:pb-2 mt-4 bg-main flex items-center justify-center gap-2'>
								<button
									type='button'
									className='w-full btn btn-secondary bg-secondary rounded-md'
									onClick={() => props.onCta(fileRef.current, name)}>
									Save
								</button>
								<button type='button'
									onClick={() => props.onClose()}
									className='w-full btn btn-primary border border-black rounded-md text-black'>
									Cancel
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
}

export const FileUploadModal = observer(Modal);
