import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { FaArrowLeft } from 'react-icons/fa';
import DonorGeneralDataTab from "./donorGeneralDataTab";
import DonorDocumentsTab from "./donorDocumentsTab";
import DonorDonationListTab from "./donorDonationListTab";

const DonorDetailsPage = observer((props: any) => {
	const { t } = useTranslation();
	const { donorId } = useParams();
	const history = useHistory();
	const [currentTabIndex, setCurrentTabIndex] = useState(0);

	return (
		<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-6 px-6">
			<div className="w-full">
				<span onClick={history.goBack} className="text-sm font-bold mb-2 flex flex-row gap-1 text-stone-400 align-middle cursor-pointer"><FaArrowLeft />{t('donors.backToList')}</span>
				<h1 className="text-4xl">{t('donors.detail')}</h1>
				<div className="bg0-primary flex text-primary">
					<div className={`px-3 py-3 cursor-pointer text-center rounded-t-xl ${currentTabIndex === 0 && "bg-white"}`} onClick={(evt) => { setCurrentTabIndex(0); }}>{t('userData.generalData')}</div>
					<div className={`px-3 py-3 cursor-pointer text-center rounded-t-xl ${currentTabIndex === 1 && "bg-white"}`} onClick={(evt) => { setCurrentTabIndex(1); }}>{t('userData.docsArchive')}</div>
					<div className={`px-3 py-3 cursor-pointer text-center rounded-t-xl ${currentTabIndex === 2 && "bg-white"}`} onClick={(evt) => { setCurrentTabIndex(2); }}>{t('userData.donationHistory')}</div>
				</div>
				<div className="bg-white hfull">
					{ currentTabIndex === 0 && <DonorGeneralDataTab id={donorId} />}
					{ currentTabIndex === 1 && <DonorDocumentsTab id={donorId} />}
					{ currentTabIndex === 2 && <DonorDonationListTab id={donorId} />}
				</div>
			</div>
		</div>
	);
});

export default DonorDetailsPage;
