import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useHistory } from 'react-router-dom';
import { useState, useRef } from "react";
import { useStores } from '../../stores/store';
import { Link } from 'react-router-dom';
import { isValidEmail, isValidCode } from '../../utils/utils';
import ErrorMessage from '../../components/other/ErrorMessage';
import PasswordField from '../../components/forms/passwordField';

enum ResetPasswordStep {
	emailStep,
	codeStep,
	passwordStep
}

export const ResetPassword = observer(() => {
	const { app, auth } = useStores();
	let history = useHistory();
	const { t } = useTranslation();

	const [isLoading, setIsLoading] = useState(false);
	const [loadingError, setLoadingError] = useState(null);
	const currentEmail = useRef(null);
	const currentPassword = useRef(null);

	const [currentStep, setCurrentStep] = useState(ResetPasswordStep.emailStep)

	const {
		register: registerEmail,
		formState: { errors: errorsEmail },
		handleSubmit: handleSubmitEmail,
	} = useForm({});

	const {
		register: registerCode,
		formState: { errors: errorsCode },
		handleSubmit: handleSubmitCode,
	} = useForm({});

	const onSubmit = (data) => {
		setIsLoading(true);
		app.restStore.otpRequest(data.email).then((res) => {
			currentEmail.current = data.email;
			setCurrentStep(ResetPasswordStep.codeStep);
		}).catch((error) => {
			setLoadingError(error);
		}).finally(() => {
			setIsLoading(false);
		});
	}

	const onSubmitCode = (data) => {
		setIsLoading(true);
		app.restStore.otpCheck(currentEmail.current, data.code).then((res) => {
			if (res.data.data.user?.role === "admin")
				auth.setAdmin(res.data.data.user, res.data.data.token);
			else
				auth.setInstitution(res.data.data.institution, res.data.data.token);
			setCurrentStep(ResetPasswordStep.passwordStep);
		}).catch((error) => {
			console.log(error);
			setLoadingError(error);
		}).finally(() => {
			setIsLoading(false);
		});
	}

	const onSubmitPassword = (event) => {
		setIsLoading(true);
		event.preventDefault();

		app.restStore.changePassword(currentPassword.current).then((res) => {
			auth.logout();
			history.replace('/login');
		}).catch((error) => {
			setLoadingError(error);
		}).finally(() => {
			setIsLoading(false);
		});
	}

	const passwordChange = (value, isGood) => {
		if (!isGood)
			currentPassword.current = null;
		else
			currentPassword.current = value.password;
	}

	const insertEmailStep = (
		<div className='bg-main rounded-md items-center h-11/12 mx-2 relative p-6 mt-8'>
			<div className='w-full flex flex-col items-center justify-center'>
				<h1 className="text-4xl text-bold mb-2">Recupera password</h1>
				<h3>Inserisci la mail per recuperare la password</h3>

				<form onSubmit={handleSubmitEmail(onSubmit)}>
					<input
						{...registerEmail('email', { required: true, validate: isValidEmail})}
						className='w-72 pl-2 py-1 mb-1 focus:border-secondary'/>

					{errorsEmail.email && <ErrorMessage>{ t('emailRequired') }</ErrorMessage>}
					{loadingError && <ErrorMessage>{ t('errors.loadingData') }</ErrorMessage> }
					<input
						type="submit" disabled={isLoading} value={t('misc.next').toString()}
						className='cursor-pointer btn bg-secondary block my-4 mx-auto w-full text-white' />
				</form>
			</div>
		</div>
	);

	const insertCodeStep = (
		<div className='bg-main rounded-md items-center h-11/12 mx-2 relative p-6 my-8'>
			<div className='w-full flex flex-col items-center justify-center'>
				<h1 className="text-4xl text-bold mb-2">Recupera password</h1>
				<h3>Inserisci il codice ricevuto via mail</h3>

				<form onSubmit={handleSubmitCode(onSubmitCode)}>
					<input
						{...registerCode('code', { required: true, validate: isValidCode})}
						className='w-72 pl-2 py-1 mb-1 focus:border-secondary'/>

					{errorsCode.code && <ErrorMessage>{ t('codeRequired') }</ErrorMessage>}
					{loadingError && <ErrorMessage>{ t('errors.loadingData') }</ErrorMessage> }
					<input
						type="submit" disabled={isLoading} value={t('misc.next').toString()}
						className='cursor-pointer btn bg-secondary block my-4 mx-auto w-full text-white' />
				</form>
			</div>
		</div>
	);

	const resetPasswordStep = (
		<div className='bg-main rounded-md items-center h-11/12 mx-2 relative p-6 my-8'>
			<div className='w-full flex flex-col items-center justify-center'>
				<h1 className="text-4xl text-bold mb-2">Recupera password</h1>
				<h3>Inserisci il codice ricevuto via mail</h3>

				<form className="dit-form" onSubmit={onSubmitPassword}>
					<PasswordField onChange={passwordChange} />
					{loadingError && <ErrorMessage>{ t('errors.loadingData') }</ErrorMessage> }
					<input
						type="submit" disabled={isLoading} value={t('misc.next').toString()}
						className='cursor-pointer btn bg-secondary block my-4 mx-auto w-full text-white' />
				</form>
			</div>
		</div>
	);

	return (
		<div className='h-full'>
			<div className='absolute top-0 bottom-0 left-0 right-0 bg-white flex opacity-25'></div>
			<div className='container mx-auto flex justify-center items-center'>
				<div className='bg-main rounded-md items-center h-11/12 mx-2 relative p-4 my-4'>
					{ currentStep === ResetPasswordStep.emailStep && insertEmailStep}
					{ currentStep === ResetPasswordStep.codeStep && insertCodeStep}
					{ currentStep === ResetPasswordStep.passwordStep && resetPasswordStep}
					<div className='text-center'>
						{ t('loginQuestion') }
						
						<Link
							to={{
								pathname: '/login',
							}}
							className='cursor-pointer block my-4 mx-auto w-full text-secondary font-bold'>
								{t('userActions.login')}
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
});