import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCheck } from 'react-icons/fa';
import ErrorMessage from "../other/ErrorMessage";

const PasswordField = (props) => {
	const { t } = useTranslation();

	const [input, setInput] = useState({
		password: '',
		confirmPassword: ''
	}); 

	const [isLengthCorrect, setIsLengthCorrect] = useState(false);
	const [isCaseCorrect, setIsCaseCorrect] = useState(false);
	const [isNumberCorrect, setIsNumberCorrect] = useState(false);
	const [passwordMatch, setPasswordMatch] = useState(true);
	const MAX_LENGTH = 8;
	var hasNumber = /\d/;

	const onInputChange = e => {
		const { name, value } = e.target;
		setInput(prev => ({
		  ...prev,
		  [name]: value
		}));
		validatePassword(e);
	  }

	const validatePassword = (evt) => {
		let { name, value } = evt.target;
		let isGood: boolean = true;

		switch (name) {
			case "password":
				setIsLengthCorrect(false);
				setIsCaseCorrect(false);
				setIsNumberCorrect(false);
				setPasswordMatch(false);
		
				if (value == null || value.length === 0) {
					isGood = false;
					return
				}
		
				if (value.length >= MAX_LENGTH)
					setIsLengthCorrect(true);
				else
					isGood = false;
		
				if (value.toUpperCase() !== value && value.toLowerCase() !== value)
					setIsCaseCorrect(true);
				else
					isGood = false;
		
				if (hasNumber.test(value))
					setIsNumberCorrect(true);
				else
					isGood = false;

				if (input.confirmPassword === value || input.password == null || input.password.length === 0)
					setPasswordMatch(true)
				else
					isGood = false;

				break;
			case "confirmPassword":
				if (input.password === value || input.confirmPassword == null || input.confirmPassword.length === 0)
					setPasswordMatch(true)
				else {
					setPasswordMatch(false);
					isGood = false;
				}

				break;
		}

		props.onChange(input, isGood);

	}

	return (
		<div className={`flex md:gap-4 ${(props.orientation && props.orientation == "horizontal" ? 'flex-row' : 'flex-col') }`}>
			<label>{t('userData.password')}
				<input type="password" name="password" autoComplete="new-password" value={input.password} onChange={onInputChange} onBlur={validatePassword} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
				<div className="flex gap-1 mb-2">
					<span className={`w-full p-0.5 ${isLengthCorrect ? 'bg-secondary' : 'bg-gray'} lg:w-1/3`}></span>
					<span className={`w-full p-0.5 ${isCaseCorrect ? 'bg-secondary' : 'bg-gray'} lg:w-1/3`}></span>
					<span className={`w-full p-0.5 ${isNumberCorrect ? 'bg-secondary' : 'bg-gray'} lg:w-1/3`}></span>
				</div>
				<div className="flex flex-col items-start mb-2 gap-1">
					<span className="flex items-center justify-start gap-1"><FaCheck color={isLengthCorrect ? 'green' : 'red'} /> {t('userData.lengthValidation')}</span>
					<span className="flex items-center gap-1"><FaCheck color={isCaseCorrect ? 'green' : 'red'} /> {t('userData.caseValidation')}</span>
					<span className="flex items-center gap-1"><FaCheck color={isNumberCorrect ? 'green' : 'red'} /> {t('userData.numberValidation')}</span>
					{ !passwordMatch && <ErrorMessage>{t('errors.passwordsNotMatch')}</ErrorMessage> }
				</div>
			</label>
			<label>{t('userData.confirmPassword')}
				<input type="password" name="confirmPassword" autoComplete="new-password" value={input.confirmPassword} onChange={onInputChange} onBlur={validatePassword} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
			</label>
		</div>
	);
}

export default PasswordField;