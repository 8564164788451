import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { useStores } from '../../../../stores/store';
import ErrorMessage from '../../../../components/other/ErrorMessage';
import { isValidEmail } from '../../../../utils/utils';
import { FaArrowLeft } from 'react-icons/fa';
import PasswordField from '../../../../components/forms/passwordField';

const AdminUserNew = observer((props: any) => {
	const { app, auth } = useStores();
	const { t } = useTranslation();
	let history = useHistory();
	const [isNormalUser, setIsNormalUser] = useState(false);
	const currentPassword = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [loadingError, setLoadingError] = useState(null);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = (data) => {
		if (currentPassword == null)
			return;

		data.password = currentPassword.current;

		setIsLoading(true);
		setLoadingError(null);
		app.restStore.addUpdateAdminUser(data).then(() => {
			history.goBack();
		}).catch((error) => {
			setLoadingError(error);
		}).finally(() => {
			setIsLoading(false);
		});
	}

	const changeRole = (role) => {
		if (role == "user") {
			setIsNormalUser(true);
		} else {
			setIsNormalUser(false);
		}
	};

	const passwordChange = (value, isGood) => {
		if (!isGood)
			currentPassword.current = null;
		else
			currentPassword.current = value.password;
	}

	return (
		<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-6 px-2">
			<div className="w-full">
				<span onClick={history.goBack} className="text-sm font-bold mb-2 flex flex-row gap-1 text-stone-400 align-middle cursor-pointer"><FaArrowLeft />{t('users.backToList')}</span>
				<h1 className="text-4xl mb-4">{t('users.add')}</h1>
				<div className="bg-white hfull flex flex-col md:flex-row md:gap-4 px-6">
					<form className="dit-form" onSubmit={handleSubmit(onSubmit)}>
						<div className='flex flex-col md:flex-row md:gap-4'>
							<label>{t('userData.role')}
								<select {...register('role', { required: true, onChange: (e) => {changeRole(e.target.value)}, })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'>
									<option value="admin">{ t('users.adminUser') }</option>
									<option value="user">{ t('users.normalUser') }</option>
								</select>
							</label>
							<label>{t('userData.email')}
								<input {...register('email', { required: true, validate: isValidEmail })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
								{errors.email && <ErrorMessage>{ t('emailRequired') }</ErrorMessage>}
							</label>
						</div>
						<div className='flex flex-col md:flex-row md:gap-4'>
							<label>{t('userData.firstName')}
								<input {...register('first_name', { required: true })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
								{errors.name && <ErrorMessage>{ t('nameRequired') }</ErrorMessage>}
							</label>
							<label>{t('userData.lastName')}
								<input {...register('last_name', { required: true })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
								{errors.surname && <ErrorMessage>{ t('surnameRequired') }</ErrorMessage>}
							</label>
						</div>
						<div className='flex flex-col md:flex-row md:gap-4'>
							<label>{t('userData.nickname')}
								<input {...register('nickname', { required: true })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
								{errors.nickname && <ErrorMessage>{ t('nicknameRequired') }</ErrorMessage>}
							</label>
						</div>
						<div> 
							<PasswordField onChange={passwordChange} orientation="horizontal" />
						</div>
						{ isNormalUser && (
							<div>
								<div className='flex flex-col md:flex-row md:gap-4'>
									<label>{t('userData.userBadge')}
										<input {...register('userBadge')} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
									</label>
								</div>
								<div className='flex flex-col md:flex-row md:gap-4'>
									<label>{t('userData.address')}
										<input {...register('address')} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
									</label>
									<label>{t('userData.zipCode')}
										<input {...register('cap', { value: null })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
									</label>
								</div>
								<div className='flex flex-col md:flex-row md:gap-4'>
									<label>{t('userData.city')}
										<input {...register('city')} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
									</label>
									<label>{t('userData.province')}
										<input {...register('province')} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
									</label>
								</div>
							</div>
						)}
						{loadingError && <ErrorMessage>{ t('errors.loadingData') }</ErrorMessage> }
						<input type="submit" disabled={isLoading} value={t('misc.save').toString()} className='cursor-pointer btn bg-secondary block my-4 mx-auto text-white float-right' />
					</form>
				</div>
			</div>
		</div>
	);
});

export default AdminUserNew;
