import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStores } from '../../../../../stores/store';
import AdminUserGeneralDataTab from "./adminUserGeneralDataTab";

const AdminUserEditPage = observer((props: any) => {
	const { app, auth } = useStores();
	const { t } = useTranslation();
	const [currentTabIndex, setCurrentTabIndex] = useState(0);

	return (
		<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-6 px-2">
			<div className="w-full md:w-3/4">
				<div className="bg0-primary flex text-primary">
					<div className={`px-3 py-3 cursor-pointer text-center rounded-t-xl ${currentTabIndex == 0 && " bg-white"}`} onClick={(evt) => { setCurrentTabIndex(0); }}>{t('userData.generalData')}</div>
				</div>
				<div className="bg-white hfull">
					{ currentTabIndex == 0 && <AdminUserGeneralDataTab data={props.data} />}
				</div>
			</div>
		</div>
	);
});

export default AdminUserEditPage;