import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { FaArrowLeft } from 'react-icons/fa';
import InstitutionGeneralDataTab from './institutionGeneralDataTab';
import InstitutionActivityListTab from "./institutionActivityListTab";

const InstitutionEditPage = observer(() => {
	const { t } = useTranslation();
	const history = useHistory();
	let { institutionId } = useParams();
	const [currentTabIndex, setCurrentTabIndex] = useState(0);

	return (
		<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-6 px-6">
			<div className="w-full">
				<span onClick={history.goBack} className="text-sm font-bold mb-2 flex flex-row gap-1 text-stone-400 align-middle cursor-pointer"><FaArrowLeft />{t('institutions.backToList')}</span>
				<h1 className="text-4xl mb-2">{t('institutions.detail')}</h1>
				<div className="bg0-primary flex text-primary">
					<div className={`px-3 py-3 cursor-pointer text-center rounded-t-xl ${currentTabIndex == 0 && " bg-white"}`} onClick={(evt) => { setCurrentTabIndex(0); }}>{t('institutions.data')}</div>
					{ institutionId && (<div className={`px-3 py-3 cursor-pointer text-center rounded-t-xl ${currentTabIndex == 1 && " bg-white"}`} onClick={(evt) => { setCurrentTabIndex(1); }}>{t('activities.activities')}</div>)}
				</div>
				<div className="bg-white hfull">
					{ currentTabIndex == 0 && <InstitutionGeneralDataTab institutionId={institutionId} />}
					{ currentTabIndex == 1 && <InstitutionActivityListTab institutionId={institutionId} />}
				</div>
			</div>
		</div>
	);
});

export default InstitutionEditPage;
