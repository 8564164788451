import { ActivityType } from "../models/activityType";
import { DonationType } from "../models/donationType";
import { Topic } from "../models/topic";

export const MAX_FILE_SIZE = 10;
export const MEDIA_IMAGE_LOGO = "image_logo";
export const MEDIA_IMAGE_CAROUSEL = "image_carousel";
export const MEDIA_DOC = "doc";
export const IMAGE_DEFAULT_NAME = "image"

export const isValidEmail = email =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);

export const isValidNin = nin => {
	var hasLetters = /[a-zA-Z]/g;

	if (nin == null || nin.length === 0)
		return false;

	if (hasLetters.test(nin)) {
		// this is a CF
		if (nin.length === 16)
			return true;
		
		return false;
	} else {
		// this is a PIVA
		if (nin.length === 11)
			return true;
		
		return false;
	}
}

export const isValidCode = nin => {
	var hasLetters = /[a-zA-Z]/g;

	if (nin == null || nin.length !== 5)
		return false;

	if (hasLetters.test(nin)) {
		return false;
	} else {
		return true;
	}
}

export const isBiggerThan = (fileSize, sizeInMb) => {
	return (fileSize / 1024 / 1024) > sizeInMb;
}

export const humanReadableTopic = (topic: Topic) => {
	switch (topic) {
		case Topic.environment_animals:
			return "Ambiente e animali";
		case Topic.research_knowledge:
			return "Ricerca e cultura";
		case Topic.society:
			return "Società";
		case Topic.urgency_oneshot:
			return "Emergenza e attualità";
		default:
			return '';
	}
}

export const humanReadableActivityType = (type: ActivityType) => {
	switch (type) {
		case ActivityType.campaign:
			return "Campagna";
		case ActivityType.subject:
			return "Contenuto";
	}
}

export const humanReadableDonationType = (type: string) => {
	switch (type) {
		case DonationType.one_shot:
			return "One shot";
		case DonationType.monthly:
			return "Mensile";
	}
}

export const getMonthShortName = month => {
	switch (month.toLowerCase()) {
		case 'january':
			return 'Gen'
		case 'february':
			return 'Feb'
		case 'march':
			return 'mar'
		case 'april':
			return 'Apr'
		case 'may':
			return 'Mag'
		case 'june':
			return 'Giu'
		case 'july':
			return 'Lug'
		case 'august':
			return 'Ago'
		case 'september':
			return 'Set'
		case 'october':
			return 'Ott'
		case 'november':
			return 'Nov'
		case 'december':
			return 'Dic'
																																				
		}
}

export const regions = [
	"Lombardia",
	"Abruzzo",
	"Basilicata",
	"Calabria",
	"Campania",
	"Emilia Romagna",
	"Friuli Venezia Giulia",
	"Lazio",
	"Liguria",
	"Marche",
	"Molise",
	"Piemonte",
	"Puglia",
	"Sardegna",
	"Sicilia",
	"Toscana",
	"Trentino Alto Adige",
	"Umbria",
	"Valle d'Aosta",
	"Veneto",
]