import React from 'react';
import { AppStore, AuthStore } from './netStore';
import { RestStore } from './restStore';

export type Stores = {
	app: AppStore,
	auth: AuthStore,
	rest: RestStore
}

export const StoresContext = React.createContext<Stores | null>(null)
