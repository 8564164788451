import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useStores} from '../../../../stores/store';
import ErrorMessage from '../../../../components/other/ErrorMessage';
import { humanReadableTopic, IMAGE_DEFAULT_NAME, isBiggerThan, isValidEmail, MAX_FILE_SIZE, MEDIA_IMAGE_CAROUSEL, MEDIA_IMAGE_LOGO, regions } from '../../../../utils/utils';
import ImageCardGrid from '../../../../components/cards/imageCardGrid';
import { Topic } from '../../../../models/topic';
import { Spinner } from '../../../../components/spinners/spinner';
import PasswordField from '../../../../components/forms/passwordField';

const InstitutionGeneralDataTab = observer((props: any) => {
	const { app, auth } = useStores();
	const { t } = useTranslation();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [loadingError, setLoadingError] = useState(null);
	const [sizeError, setSizeError] = useState(false);
	const [data, setData] = useState(null);
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [fetchingError, setFetchingError] = useState(null);
	const logoFileLoadRef = useRef(null);
	const carouselFileLoadRef = useRef(null);

	const [modifyPassword, setModifyPassword] = useState(false);
	const currentPassword = useRef(null);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	const refresh = () => {
		if (props.institutionId === null) {
			setIsFetchingData(false);
			return;
		}

		setIsFetchingData(true);
		app.restStore.getAdminInstitution(props.institutionId).then((res) => {
			reset(res.data.data);
			setData(res.data.data);
		}).catch((error) => {
			setFetchingError(error);
		}).finally(() => {
			setIsFetchingData(false);
		});
	}

	useEffect(() => {
		refresh();
	}, []);

	const onSubmit = (data) => {
		setIsLoading(true);
		setLoadingError(null);
		app.restStore.addUpdateInstitutionUser(data).then(async () => {
			if (modifyPassword && currentPassword.current != null) {
				await app.restStore.changePassword(currentPassword.current);
			}
			history.push("/admin/institutions/")
		}).catch((error) => {
			setLoadingError(error);
		}).finally(() => {
			setIsLoading(false);			
		});
	}

	const loadLogoImage = (event) => {
		event.preventDefault();
		logoFileLoadRef.current.click();
	}

	const loadCarouselImage = (event) => {
		event.preventDefault();
		carouselFileLoadRef.current.click();
	}

	const logoFileUploadHandler = (event) => {
		event.preventDefault();

		var file = event.target.files[0];
		let fileSize = event.target.files[0].size;

		if (isBiggerThan(fileSize, MAX_FILE_SIZE)) {
			setSizeError(true);
			return;
		}

		var reader = new FileReader();
		reader.onload = function(event) {
			addLogoMedia(event.target.result);
		};
	  
		reader.readAsDataURL(file);
	};

	const addLogoMedia = (imageData) => {
		app.restStore.addMedia(MEDIA_IMAGE_LOGO, IMAGE_DEFAULT_NAME, imageData).then((res) => {
			let logo = res.data.data.id;

			app.restStore.associateMediaToInstitution(true, props.institutionId, logo).then((res) => {
				refresh();
			}).catch((error) => {
			}).finally(() => {
			});
		}).catch((error) => {
		}).finally(() => {
		});
	}

	const addCarouselMedia = (imageData) => {
		app.restStore.addMedia(MEDIA_IMAGE_CAROUSEL, IMAGE_DEFAULT_NAME, imageData).then((res) => {
			let carousel_imgs = [res.data.data.id];

			app.restStore.associateMediaToInstitution(false, props.institutionId, carousel_imgs).then((res) => {
				refresh();
			}).catch((error) => {
			}).finally(() => {
			});
	
		}).catch((error) => {
		}).finally(() => {
		});
	}

	const carouselFileUploadHandler = (event) => {
		event.preventDefault();

		var file = event.target.files[0];
		let fileSize = event.target.files[0].size;

		if (isBiggerThan(fileSize, MAX_FILE_SIZE)) {
			setSizeError(true);
			return;
		}

		var reader = new FileReader();
		reader.onload = function(event) {
			addCarouselMedia(event.target.result);
		};
	  
		reader.readAsDataURL(file);
	};

	const deleteMedia = (id) => {
		app.restStore.deleteMedia(id).then((res) => {
			refresh();
		}).catch((error) => {
		}).finally(() => {
		});
	}

	const stripeOnboard = () => {
		app.restStore.addAdminStripeOnboarding(props.institutionId).then((res) => {
			window.open(res.data.data);
		}).catch((error) => {
		}).finally(() => {
		});
	}

	const passwordChange = (value, isGood) => {
		if (!isGood)
			currentPassword.current = null;
		else
			currentPassword.current = value.password;
	}

	const panel = (<>
		<div className='flex justify-end items-end gap-1'>
			{ data?.is_active && (<label className='bg-green-400 rounded-full p-1'>{t('userData.subscriptionActive')}</label>) }
			{ !data?.is_active && (<label className='bg-red-300 rounded-full p-1'>{t('userData.subscriptionNotActive')}</label>) }
			<div className='flex justify-start items-start gap-1'>
				{ !data?.is_onboarding && (<button
					onClick={stripeOnboard}
					className='cursor-pointer p-1 bg-secondary text-white rounded-full float-right'
					>{t('userActions.stripeOnboard')}</button>
				)}
			</div>
		</div>
		<form className="dit-form" onSubmit={handleSubmit(onSubmit)}>
			<div className='flex flex-col md:flex-row md:gap-4'>
				<label>{t('userData.name')}
					<input {...register('name', { required: true })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
					{errors.name && <ErrorMessage>{ t('nameRequired') }</ErrorMessage>}
				</label>
				<label>{t('userData.email')}
					<input {...register('email', { required: true, validate: isValidEmail })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
					{errors.email && <ErrorMessage>{ t('emailRequired') }</ErrorMessage>}
				</label>
			</div>
			<div className='flex flex-col md:flex-row md:gap-4'>
				<label>{t('userData.nin')}
					<input {...register('p_iva_cf', { required: true })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
					{errors.name && <ErrorMessage>{ t('ninRequired') }</ErrorMessage>}
				</label>
			</div>
			<div className='flex flex-col md:flex-row md:gap-4'>
				<button
					onClick={(evt) => {evt.preventDefault(); setModifyPassword(!modifyPassword)}}
					className="cursor-pointer block my-4 mx-auto w-full text-secondary font-bold"
				>
					{t('userActions.modifyPassword').toString()}
				</button>
			</div>
			<div className='flex flex-col md:flex-row md:gap-4'>
				{ modifyPassword && (
					<div className='flex flex-col md:flex-row md:gap-4'> 
						<PasswordField onChange={passwordChange} orientation="horizontal" />
					</div>
				)}
			</div>
			<div className='flex flex-col md:flex-row md:gap-4'>
				<label>{t('userData.region')}
					<select {...register('region', { required: true })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'>
						{ Object.keys(regions).map(key => (
							<option key={key} value={regions[key]}>
								{regions[key]}
							</option>
						))}
					</select>
					{errors.region && <ErrorMessage>{ t('regionRequired') }</ErrorMessage>}
				</label>

				<label>{t('userData.topic')}
					<select {...register('topic', { required: true })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'>
						{Object.values(Topic).map(value =>
							<option value={value} key={value}>{humanReadableTopic(value)}</option>
						)}
					</select>
					{errors.topic && <ErrorMessage>{ t('topicRequired') }</ErrorMessage>}
				</label>
			</div>
			<div className='flex flex-col md:flex-row md:gap-4'>
				<label>{t('userData.payoff')}
					<input {...register('payoff')} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
				</label>
			</div>
			<label>{t('userData.description')}
			<textarea {...register('description')} className='w-full pl-2 py-1 mb-1 focus:border-secondary block'/>
			</label>
			
			<input type="file" name="fileLogo"
				ref={logoFileLoadRef} accept="image/*" hidden
				onChange={logoFileUploadHandler}
			/>
			<input type="file" name="carouselFile"
				ref={carouselFileLoadRef} accept="image/*" hidden
				onChange={carouselFileUploadHandler}
			/>

			<div>
				<div>
					<label className='mb-2'>{t('misc.logo')}</label>
					<button
						onClick={loadLogoImage}
						className='cursor-pointer btn bg-secondary text-white mb-2'
					>{t('userActions.addLogo')}</button>
				</div>
				<div className='mb-2'>
					<ImageCardGrid images={data?.Media.filter((el) => el.type == "image_logo")} delete={deleteMedia} />
				</div>
			</div>

			<div>
				<div>
					<label className='mb-2'>{t('misc.images')}</label>
					<button
						onClick={loadCarouselImage}
						className='cursor-pointer btn bg-secondary text-white mb-2'
					>{t('userActions.addImage')}</button>
				</div>
				<div className='mb-2'>
					<ImageCardGrid images={data?.Media.filter((el) => el.type == "image_carousel")} delete={deleteMedia} />
				</div>
			</div>

			{loadingError && <ErrorMessage>{ t('errors.loadingData') }</ErrorMessage> }
			{sizeError && <ErrorMessage>{ t('errors.fileIsTooBig') }</ErrorMessage> }
			<input type="submit" disabled={isLoading} value={t('misc.save').toString()} className='cursor-pointer btn bg-secondary block my-2 mx-auto float-right text-white' />
		</form>
		</>
	);

	return (
		<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-2 px-6">
			<div className="w-full md:w-11/12">
				{ isFetchingData && <Spinner /> }
				{ !isFetchingData && !fetchingError && panel }
				{ fetchingError && <ErrorMessage text={fetchingError} />}
			</div>
        </div>
	);
});

export default InstitutionGeneralDataTab;
