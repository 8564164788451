import React from 'react';
import { useTranslation } from 'react-i18next';

export function Page404() {
  const { t } = useTranslation();
  return (
    <div className='container absolute top-0 bottom-0 left-0 right-0 flex-col flex items-center justify-center'>
      <h1 className='text-5xl font-bold'> {t('error')} 404</h1>
      <p>{t('pagenotfound')}</p>
    </div>
  );
}
