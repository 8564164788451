import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import InstitutionUserGeneralDataTab from "../institutionUser/institutionUserGeneralDataTab";
import InstitutionUserDocumentsTab from "./institutionUserDocumentsTab";
import InstitutionUserFriendsTab from "./institutionUserFriendsTab";
import InstitutionUserFollowingTab from "./institutionUserFollowingTab";
import InstitutionUserDonationHistoryTab from "./institutionUserDonationHistoryTab";

const InstitutionUserEditPage = observer((props: any) => {
	const { t } = useTranslation();
	const [currentTabIndex, setCurrentTabIndex] = useState(0);

	return (
		<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-6 px-2">
			<div className="w-full">
				<div className="bg0-primary flex text-primary">
					<div className={`px-3 py-3 cursor-pointer text-center rounded-t-xl ${currentTabIndex === 0 && "bg-white"}`} onClick={(evt) => { setCurrentTabIndex(0); }}>{t('userData.generalData')}</div>
					<div className={`px-3 py-3 cursor-pointer text-center rounded-t-xl ${currentTabIndex === 1 && "bg-white"}`} onClick={(evt) => { setCurrentTabIndex(1); }}>{t('userData.docsArchive')}</div>
					<div className={`px-3 py-3 cursor-pointer text-center rounded-t-xl ${currentTabIndex === 2 && "bg-white"}`} onClick={(evt) => { setCurrentTabIndex(2); }}>{t('userData.friends')}</div>
					<div className={`px-3 py-3 cursor-pointer text-center rounded-t-xl ${currentTabIndex === 3 && "bg-white"}`} onClick={(evt) => { setCurrentTabIndex(3); }}>{t('userData.institutionsFollowed')}</div>
					<div className={`px-3 py-3 cursor-pointer text-center rounded-t-xl ${currentTabIndex === 4 && "bg-white"}`} onClick={(evt) => { setCurrentTabIndex(4); }}>{t('userData.donationHistory')}</div>
				</div>
				<div className="bg-white hfull">
					{ currentTabIndex === 0 && <InstitutionUserGeneralDataTab id={props.data.id} />}
					{ currentTabIndex === 1 && <InstitutionUserDocumentsTab id={props.data.id} />}
					{ currentTabIndex === 2 && <InstitutionUserFriendsTab id={props.data.id} />}
					{ currentTabIndex === 3 && <InstitutionUserFollowingTab id={props.data.id} />}
					{ currentTabIndex === 4 && <InstitutionUserDonationHistoryTab id={props.data.id} />}
				</div>
			</div>
		</div>
	);
});

export default InstitutionUserEditPage;