import { observer } from 'mobx-react-lite';

const ImageCardGrid = observer((props: any) => {

	return (
			<div className='mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-3'>
				{props.images && props.images.map(el => (
					<div>
						<div className='border border-gray shadow-md rounded-xl flex justify-center'>
							<img alt="grid_image" className="h-8" src={el.url} />
						</div>
						<img alt="delete" onClick={() => props.delete(el.id)} className="relative bottom-2 right-2 cursor-pointer" src="/icons/cancel.png" />
					</div>
				))}
				{props.localImages && props.localImages.map((el, idx) => (
					<div>
						<div className='border border-gray shadow-md rounded-xl flex justify-center'>
							<img alt="grid_image" className="h-8" src={el} />
						</div>
						<img alt="delete" onClick={() => props.localDelete(idx)} className="relative bottom-2 right-2 cursor-pointer" src="/icons/cancel.png" />
					</div>
				))}

			</div>
	);
});

export default ImageCardGrid;
