import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../../stores/store";
import DTable from "../../../../../components/tables/DTable"
import { UserDocument } from "../../../../../models/userDocument"

import { createColumnHelper } from '@tanstack/react-table'
import { Spinner } from "../../../../../components/spinners/spinner";
import ErrorMessage from "../../../../../components/other/ErrorMessage";
import { BaseModal } from "../../../../../components/modals/baseModal";
import { FileUploadModal } from "../../../../../components/modals/fileUploadModal";
import { MEDIA_DOC } from "../../../../../utils/utils";

const InstitutionUserDocumentsTab = observer((props: any) => {
	const { app } = useStores();
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [fetchingError, setFetchingError] = useState(null);
	const [deleteAsk, setDeleteAsk] = useState(null);
	const [addDocumentPopup, setAddDocumentPopup] = useState(false);

	const columnHelper = createColumnHelper<UserDocument>()

	const columns = [
		columnHelper.accessor('url', {
			header: () => <span>{t('documents.download')}</span>,
			cell: info => <a target="_blank" href={info.getValue()}>Download</a>,
			footer: info => info.column.id,
		}),
		columnHelper.accessor('title', {
			header: () => <span>{t('documents.name')}</span>,
			cell: info => info.getValue(),
			footer: info => info.column.id,
		}),
		columnHelper.accessor('created_at', {
			header: () => <span>{t('documents.uploadDate')}</span>,
			cell: info => { return (new Date(info.getValue()).toLocaleDateString('it')) },
			footer: info => info.column.id,
		}),
	]

	const refresh = () => {
		setIsFetchingData(true);
		app.restStore.getUserDocuments(props.id).then((res) => {
			console.log(res.data.data.docs)
			setData(res.data.data.docs);
		}).catch((error) => {
			setFetchingError(error);
		}).finally(() => {
			setIsFetchingData(false);			
		});
	}

	useEffect(() => {
		refresh();
	}, []);

	const addDocument = (data, title) => {
		app.restStore.addMedia(MEDIA_DOC, title, data).then((res) => {
			let mediaId = res.data.data.id;
			app.restStore.associateDoc(props.id, mediaId).then((res) => {
				refresh();
			})
			setAddDocumentPopup(false);
		}).catch((error) => {
		}).finally(() => {
		});
	}

	const deleteItem = (id: string) => {
		setDeleteAsk(id);
	}

	const doDeleteItem = (id: string) => {
		app.restStore.deleteUserDocuments(props.id, id).then((res) => {
			refresh();
			setDeleteAsk(null);
		}).catch((error) => {
		}).finally(() => {
		});
	}

	const deleteModal = (
		<BaseModal
			isOpen={deleteAsk != null}
			onClose={() => {setDeleteAsk(null)}}
			onCta={() => { doDeleteItem(deleteAsk) }}
			ctaText={t('misc.yes')}
			closeText={t('misc.no')}
			title={t('userActions.delete')}
		>
			<div>
				<div className='font-semibold text-4xl text-black mb-4'>{t('userActions.delete')}</div>
				<div className='px-4 sm:px-6 pb-4 sm:pb-6 text-black'>{t('userActions.confirmDelete')}</div>
			</div>
		</BaseModal>
	)

	return (
		<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-6 px-6">
			<div className="w-full">
				{ isFetchingData && <Spinner /> }
				{ !isFetchingData && !fetchingError && (
					<div>
						<button className="cursor-pointer p-1 bg-secondary text-white rounded-full float-right mb-2" onClick={() => setAddDocumentPopup(true)}>{t('userActions.addNew')}</button>
						<DTable columns={columns} rows={data} actionsEnabled={true} hasModify={false} delete={deleteItem} />
					</div>
				)}
				{ fetchingError && <ErrorMessage text={fetchingError} />}
			</div>

			<FileUploadModal isOpen={addDocumentPopup} onCta={addDocument} onClose={() => {setAddDocumentPopup(false)}}/>
			{ deleteModal }
		</div>
    );
});

export default InstitutionUserDocumentsTab;
