function isProd() {
    return process.env.NODE_ENV === 'production';
}

export const config = {
    env: isProd() ? "production" : "development",
    apiEndpoint: isProd() ? "https://donatid-api.duckma.me" : "https://devdonatid.duckma.me",
    apiBaseUri: '/api/v1',

    getEndpointUri() {
        return `${config.apiEndpoint}${config.apiBaseUri}`
    }
}
