import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../../stores/store";
import DTable from "../../../../../components/tables/DTable"

import { createColumnHelper } from '@tanstack/react-table'
import { Spinner } from "../../../../../components/spinners/spinner";
import ErrorMessage from "../../../../../components/other/ErrorMessage";
import { Institution } from "../../../../../models/institution";

const InstitutionUserFollowingTab = observer((props: any) => {
	const { app } = useStores();
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [fetchingError, setFetchingError] = useState(null);

	const columnHelper = createColumnHelper<Institution>()

	const columns = [
		columnHelper.accessor('name', {
			header: () => <span>{t('userData.name')}</span>,
			cell: info => info.getValue(),
			footer: info => info.column.id,
		}),
		columnHelper.accessor('email', {
			header: () => <span>{t('userData.email')}</span>,
			footer: info => info.column.id,
		}),
	]

	useEffect(() => {
		setIsFetchingData(true);
		app.restStore.getUserFollowing(props.id).then((res) => {
			console.log(res.data.data);
			setData(res.data.data.supports.Institutions);
		}).catch((error) => {
			setFetchingError(error);
		}).finally(() => {
			setIsFetchingData(false);			
		});
	}, []);

	return (
		<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-6 px-6">
			<div className="w-full">
				{ isFetchingData && <Spinner /> }
				{ !isFetchingData && !fetchingError && <DTable columns={columns} rows={data} actionsEnabled={false} /> }
				{ fetchingError && <ErrorMessage text={fetchingError} />}
			</div>
		</div>
    );
});

export default InstitutionUserFollowingTab;
