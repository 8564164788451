import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useEffect, useState, useRef } from "react";
import { useStores } from '../../../stores/store';
import { isValidEmail } from '../../../utils/utils';
import ErrorMessage from '../../../components/other/ErrorMessage';
import PasswordField from '../../../components/forms/passwordField';

const AdminProfilePage = observer(() => {
	const { app, auth } = useStores();
	const { t } = useTranslation();
	const history = useHistory();

	const [isLoading, setIsLoading] = useState(false);
	const [loadingError, setLoadingError] = useState(null);

	const [modifyPassword, setModifyPassword] = useState(false);
	const currentPassword = useRef(null);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	useEffect(() => {
		reset(auth.profile.user);
	}, []);

	const onSubmit = (data) => {
		setIsLoading(true);
		setLoadingError(null);
		app.restStore.addUpdateAdminUser(data).then(() => {
			auth.setAdmin(data, null);
			if (modifyPassword && currentPassword != null) {
				app.restStore.changePassword(currentPassword.current).then(() => {
					history.push('/admin/dashboard/');
				});
			} else {
				history.push('/admin/dashboard/');
			}
		}).catch((error) => {
			setLoadingError(error);
		}).finally(() => {
			setIsLoading(false);
		});
	}

	const passwordChange = (value, isGood) => {
		if (!isGood)
			currentPassword.current = null;
		else
			currentPassword.current = value.password;
	}

	return (
		<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-4 px-2 justify-center">
			<div className="w-full md:w-3/4">
				<h1 className="text-4xl mb-2">{t('userData.profile')}</h1>
				<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-4 px-6 justify-center bg-white">
					<form className="dit-form" onSubmit={handleSubmit(onSubmit)}>
						<div className='flex flex-col md:flex-row md:gap-4'>
							<label>{t('userData.firstName')}
								<input {...register('first_name', { required: true, })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
								{errors.name && <ErrorMessage>{ t('nameRequired') }</ErrorMessage>}
							</label>
							<label>{t('userData.lastName')}
								<input {...register('last_name', { required: true })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
								{errors.surname && <ErrorMessage>{ t('surnameRequired') }</ErrorMessage>}
							</label>
						</div>
						<div className='flex flex-col md:flex-row md:gap-4'>
							<label>{t('userData.email')}
								<input {...register('email', { required: true, validate: isValidEmail })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
								{errors.email && <ErrorMessage>{ t('emailRequired') }</ErrorMessage>}
							</label>
						</div>
						<button
							onClick={(evt) => {evt.preventDefault(); setModifyPassword(!modifyPassword)}}
							className="cursor-pointer block my-4 mx-auto w-full text-secondary font-bold"
						>
							{t('userActions.modifyPassword').toString()}
						</button>
						{ modifyPassword &&
							<div className='flex flex-col md:flex-row md:gap-4'> 
								<PasswordField onChange={passwordChange} orientation={"horizontal"} />
							</div>
						}
						{loadingError && <ErrorMessage>{ t('errors.loadingData') }</ErrorMessage> }
						<input type="submit" disabled={isLoading} value={t('misc.save').toString()} className='cursor-pointer btn bg-secondary block my-2 mx-auto float-right text-white' />
					</form>
				</div>
			</div>
		</div>
	);
});

export default AdminProfilePage;
