import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useStores} from '../../../stores/store';
import ErrorMessage from '../../../components/other/ErrorMessage';
import { humanReadableTopic, IMAGE_DEFAULT_NAME, isBiggerThan, isValidEmail, MAX_FILE_SIZE, MEDIA_IMAGE_CAROUSEL, MEDIA_IMAGE_LOGO, regions } from '../../../utils/utils';
import ImageCardGrid from '../../../components/cards/imageCardGrid';
import { Topic } from '../../../models/topic';
import PasswordField from '../../../components/forms/passwordField';
import { Spinner } from '../../../components/spinners/spinner';
import { FaArrowLeft } from 'react-icons/fa';

const InstitutionNewPage = observer((props: any) => {
	const { app, auth } = useStores();
	const { t } = useTranslation();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [loadingError, setLoadingError] = useState(null);
	const [sizeError, setSizeError] = useState(false);
	const [logoData, setLogoData] = useState([]);
	const [carouselData, setCarouselData] = useState([]);
	const logoFileLoadRef = useRef(null);
	const carouselFileLoadRef = useRef(null);

	const currentPassword = useRef(null);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = (data) => {
		if (currentPassword.current == null)
			return;

		data['password'] = currentPassword.current;

		setIsLoading(true);
		setLoadingError(null);
		app.restStore.addUpdateInstitutionUser(data).then(async (institutionRes) => {
			/* Logo image */
			let logoImg = [];

			for (var i = 0; i < logoData.length; i++) {
				let image = logoData[i];
				let res = await app.restStore.addMedia(MEDIA_IMAGE_LOGO, IMAGE_DEFAULT_NAME, image);
				logoImg.push(res.data.data.id)
			}
			if (logoImg.length > 0)
				await app.restStore.associateMediaToInstitution(true, institutionRes.data.data.institution.id, logoImg[0]);

			/* Carousel images */
			let carouselImgs = [];

			for (var i = 0; i < carouselData.length; i++) {
				let image = carouselData[i];
				let res = await app.restStore.addMedia(MEDIA_IMAGE_CAROUSEL, IMAGE_DEFAULT_NAME, image);
				carouselImgs.push(res.data.data.id)
			}
			if (carouselImgs.length > 0)
				await app.restStore.associateMediaToInstitution(false, institutionRes.data.data.institution.id, carouselImgs);
			
			history.push("/admin/institutions/")
		}).catch((error) => {
			setLoadingError(error);
		}).finally(() => {
			setIsLoading(false);
		});
	}

	const loadLogoImage = (event) => {
		event.preventDefault();
		logoFileLoadRef.current.click();
	}

	const loadCarouselImage = (event) => {
		event.preventDefault();
		carouselFileLoadRef.current.click();
	}

	const logoFileUploadHandler = (event) => {
		event.preventDefault();

		var file = event.target.files[0];
		let fileSize = event.target.files[0].size;

		if (isBiggerThan(fileSize, MAX_FILE_SIZE)) {
			setSizeError(true);
			return;
		}

		var reader = new FileReader();
		reader.onload = function(event) {
			addLogoMedia(event.target.result);
		};
	  
		reader.readAsDataURL(file);
	};

	const addLogoMedia = (imageData) => {
		setLogoData([imageData])
	}

	const addCarouselMedia = (imageData) => {
		setCarouselData([...carouselData, imageData])
	}

	const carouselFileUploadHandler = (event) => {
		event.preventDefault();

		var file = event.target.files[0];
		let fileSize = event.target.files[0].size;

		if (isBiggerThan(fileSize, MAX_FILE_SIZE)) {
			setSizeError(true);
			return;
		}

		var reader = new FileReader();
		reader.onload = function(event) {
			addCarouselMedia(event.target.result);
		};
	  
		reader.readAsDataURL(file);
	};

	const passwordChange = (value, isGood) => {
		if (!isGood)
			currentPassword.current = null;
		else
			currentPassword.current = value.password;
	}

	const deleteLogoData = (idx) => {
		setLogoData([])
	}

	const deleteCarouselData = (idx) => {
		setCarouselData(carouselData.filter((item, elmIdx) => idx !== elmIdx))
	}

	const panel = (
		<form className="dit-form" onSubmit={handleSubmit(onSubmit)}>
			<div className='flex flex-col md:flex-row md:gap-4'>
				<label>{t('userData.name')}
					<input {...register('name', { required: true })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
					{errors.name && <ErrorMessage>{ t('nameRequired') }</ErrorMessage>}
				</label>
				<label>{t('userData.email')}
					<input {...register('email', { required: true, validate: isValidEmail })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
					{errors.email && <ErrorMessage>{ t('emailRequired') }</ErrorMessage>}
				</label>
			</div>
			<div className='flex flex-col md:flex-row md:gap-4'>
				<label>{t('userData.nin')}
					<input {...register('p_iva_cf', { required: true })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
					{errors.name && <ErrorMessage>{ t('ninRequired') }</ErrorMessage>}
				</label>
			</div>

			<div className='flex flex-col md:flex-row md:gap-4'> 
				<PasswordField onChange={passwordChange} orientation="horizontal" />
			</div>
			<div className='flex flex-col md:flex-row md:gap-4'>
				<label>{t('userData.region')}
					<select {...register('region', { required: true })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'>
						{ Object.keys(regions).map(key => (
							<option key={key} value={regions[key]}>
								{regions[key]}
							</option>
						))}
					</select>
					{errors.region && <ErrorMessage>{ t('regionRequired') }</ErrorMessage>}
				</label>

				<label>{t('userData.topic')}
					<select {...register('topic', { required: true })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'>
						{Object.values(Topic).map(value =>
							<option value={value} key={value}>{humanReadableTopic(value)}</option>
						)}
					</select>
					{errors.topic && <ErrorMessage>{ t('topicRequired') }</ErrorMessage>}
				</label>
			</div>
			<div className='flex flex-col md:flex-row md:gap-4'>
				<label>{t('userData.payoff')}
					<input {...register('payoff')} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
				</label>
			</div>
			<label>{t('userData.description')}
			<textarea {...register('description')} className='w-full pl-2 py-1 mb-1 focus:border-secondary block'/>
			</label>
			
			<input type="file" name="fileLogo"
				ref={logoFileLoadRef} accept="image/*" hidden
				onChange={logoFileUploadHandler}
			/>
			<input type="file" name="carouselFile"
				ref={carouselFileLoadRef} accept="image/*" hidden
				onChange={carouselFileUploadHandler}
			/>

			<div>
				<div>
					<label className='mb-2'>{t('misc.logo')}</label>
					<button
						onClick={loadLogoImage}
						className='cursor-pointer btn bg-secondary text-white mb-2'
					>{t('userActions.addLogo')}</button>
				</div>
				<div className='mb-2'>
					<ImageCardGrid localImages={logoData} localDelete={deleteLogoData} />
				</div>
			</div>

			<div>
				<div>
					<label className='mb-2'>{t('misc.images')}</label>
					<button
						onClick={loadCarouselImage}
						className='cursor-pointer btn bg-secondary text-white mb-2'
					>{t('userActions.addImage')}</button>
				</div>
				<div className='mb-2'>
					<ImageCardGrid localImages={carouselData} localDelete={deleteCarouselData} />
				</div>
			</div>

			{loadingError && <ErrorMessage>{ t('errors.loadingData') }</ErrorMessage> }
			{sizeError && <ErrorMessage>{ t('errors.fileIsTooBig') }</ErrorMessage> }
			<input type="submit" disabled={isLoading} value={t('misc.save').toString()} className='cursor-pointer btn bg-secondary block my-2 mx-auto float-right text-white' />
		</form>
	);

	return (
		<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-4 px-2 justify-center">
			<div className="w-full md:w-11/12">
				<span onClick={history.goBack} className="text-sm font-bold mb-2 flex flex-row gap-1 text-stone-400 align-middle cursor-pointer"><FaArrowLeft />{t('institutions.backToList')}</span>
				<h1 className="text-4xl mb-2">{t('institutions.addNew')}</h1>
				<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-4 px-6 justify-center bg-white">
					{isLoading && <Spinner />}
					{!isLoading && panel}
				</div>
			</div>
		</div>
	)
});

export default InstitutionNewPage;
