import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../stores/store";
import DTable from "../../../components/tables/DTable"

import { createColumnHelper } from '@tanstack/react-table'
import { Donor } from "../../../models/donor";
import { Spinner } from "../../../components/spinners/spinner";
import ErrorMessage from "../../../components/other/ErrorMessage";

const DonorListPage = observer(() => {
	const { app } = useStores();
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [fetchingError, setFetchingError] = useState(null);

	const columnHelper = createColumnHelper<Donor>()

	const columns = [
		columnHelper.accessor('first_name', {
			header: () => <span>{t('userData.firstName')}</span>,
			cell: info => info.getValue(),
			footer: info => info.column.id,
		}),
		columnHelper.accessor('last_name', {
			header: () => <span>{t('userData.lastName')}</span>,
			cell: info => info.getValue(),
			footer: info => info.column.id,
		}),
		columnHelper.accessor('cf', {
			header: () => <span>{t('userData.nin')}</span>,
			cell: info => info.getValue(),
			footer: info => info.column.id,
		}),
		columnHelper.accessor('email', {
			header: () => <span>{t('userData.email')}</span>,
			footer: info => info.column.id,
		}),
		columnHelper.accessor('address', {
			header: () => <span>{t('userData.address')}</span>,
			cell: info => info.getValue(),
			footer: info => info.column.id,
		}),
		columnHelper.accessor('total_donations', {
			header: () => <span>{t('donors.total_donations')}</span>,
			cell: info => info.getValue() + " €",
			footer: info => info.column.id,
		}),
	]

	useEffect(() => {
		setIsFetchingData(true);
		app.restStore.getDonors().then((res) => {
			console.log(res.data.data.donors);
			setData(res.data.data.donors);
		}).catch((error) => {
			setFetchingError(error);
		}).finally(() => {
			setIsFetchingData(false);			
		});
    }, []);

	return (
		<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-6 px-2">
			<div className="w-full md:w-11/12">
				<h1 className="text-4xl">{t('donors.list')}</h1>
				{ isFetchingData && <Spinner /> }
				{ !isFetchingData && !fetchingError && <DTable columns={columns} rows={data} actionsEnabled={false} detailsEnabled={true} /> }
				{ fetchingError && <ErrorMessage text={fetchingError} />}
			</div>
        </div>
    );
});

export default DonorListPage;