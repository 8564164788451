import { makeAutoObservable } from "mobx";
import i18n from "../i18n/i18n";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { RestStore } from "./restStore";
import { config } from "../config";
import { User } from "../models/user";
import { Profile } from "../models/profile";
import { Institution } from "../models/institution";
import { Role } from "../models/role";

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 5 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

// Add a response interceptor
api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // 
    config.headers["Accept-Language"] = i18n.language || "en";
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export class AuthStore {
  profile: Profile | null;
  token: string;
  loggingIn: boolean;
  loginError: any;

  constructor() {
    makeAutoObservable(this);
    this.loggingIn = false;

    try {
      this.profile = JSON.parse(window.localStorage.getItem("profile"));
      this.token = JSON.parse(window.localStorage.getItem("token"));
    } catch {
      this.profile = null;
      this.token = null;
    }
  }

  updateProfile = (profile) =>{
    this.profile = profile;
    if (profile.token) {
      this.token = profile?.token;
    }

    window.localStorage.setItem("token", JSON.stringify(this.token))
    this.setStoredProfile(profile);
  }

  setAdmin = (data: User, token: string) => {
    if (token == null)
      token = this.profile?.token;

    const updateProfile: Profile = {
      id: data.id,
      name: `${data.first_name} ${data.last_name}`,
      role: Role.admin,
      token: token,
      email: data.email,
      user: data
    };

    this.updateProfile(updateProfile);
  }

  setInstitution = (data: Institution, token: string) => {
    const profile: Profile = {
      id: data.id,
      name: `${data.name}`,
      role: Role.institution,
      email: data.email,
      token: token,
      institution: data
    };

    this.updateProfile(profile);
  }

  login = async (email: string, password: string) => {
    this.loggingIn = true;
    
    try {
      const response = await api.post(`${config.getEndpointUri()}/auth/login/`, {
        email,
        password,
      });

      if (response.data.error != null) {
        this.loginError = "Wrong auth info";
        return response.data;
      } else {
        let data = response.data.data;

        if (data.user !== undefined) {
          this.setAdmin(data.user, data.token);
        } else {
          this.setInstitution(data.institution, data.token);
        }

        return response.data;
      }
    } catch (error) {
      this.loginError = error.response;
    } finally {
      this.loggingIn = false;
    }
  };

  setStoredProfile = (data) => {
    window.localStorage.setItem(
      "profile",
      JSON.stringify(data)
    );
  }

  logout = () => {
    this.profile = null;
    window.localStorage.clear();
    window.location.replace(window.location.origin + "/login/")
  };
}

export class AppStore {
  authStore: AuthStore = null;
  restStore: RestStore = null;

  constructor(authStore: AuthStore, restStore: RestStore) {
    this.authStore = authStore;
    this.restStore = restStore;
    makeAutoObservable(this);
  }
}