/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { observer } from 'mobx-react-lite';

function Modal(props: {
	isOpen: boolean;
	onClose: Function;
	onCta: Function;
	title: string;
	children: any;
	closeText?: string;
	ctaText: string;
}) {
	const cancelButtonRef = useRef(null);

	return (
		<Transition.Root show={props.isOpen} as={Fragment}>
			<Dialog
				as='div'
				static
				style={{ zIndex: 1000 }}
				className='fixed inset-0 overflow-y-auto w-full h-screen'
				initialFocus={cancelButtonRef}
				open={props.isOpen}
				//   @ts-ignore
				onClose={props.onClose}>
				<div className='flex w-full h-full items-center justify-center text-white text-sm'>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'>
							<Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-25 transition-opacity' />
					</Transition.Child>

					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
						enterTo='opacity-100 translate-y-0 sm:scale-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100 translate-y-0 sm:scale-100'
						leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
						<div className='bg-main relative w-10/12 sm:w-20 rounded-md'>
							<div className='px-1 sm:px-1'>
								<div className='sm:flex sm:items-start'>
									<div className='mt-1 w-full'>
										{props.children}
									</div>
								</div>
							</div>
							<div className='px-4 sm:px-6 pb-4 sm:pb-6 bg-main flex items-center justify-center gap-2'>
								<button
								type='button'
								className='w-full btn btn-secondary bg-secondary rounded-md'
								onClick={() => props.onCta()}>
								{props.ctaText}
								</button>
								<button type='button' className='w-full btn btn-primary border border-black rounded-md text-black' onClick={() => props.onClose()} ref={cancelButtonRef}>
								{props.closeText}
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
}

export const BaseModal = observer(Modal);
