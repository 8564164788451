import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../../stores/store";
import DTable from "../../../../../components/tables/DTable"
import type { User } from "../../../../../models/user";

import { createColumnHelper } from '@tanstack/react-table'
import { Spinner } from "../../../../../components/spinners/spinner";
import ErrorMessage from "../../../../../components/other/ErrorMessage";

const InstitutionUserFriendsTab = observer((props: any) => {
	const { app } = useStores();
	const { t } = useTranslation();
	const [data, setData] = useState(null);
	const [currentTabIndex, setCurrentTabIndex] = useState(0);
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [fetchingError, setFetchingError] = useState(null);

	const columnHelper = createColumnHelper<User>()

	const columns = [
		columnHelper.accessor('first_name', {
			header: () => <span>{t('userData.firstName')}</span>,
			cell: info => info.getValue(),
			footer: info => info.column.id,
		}),
		columnHelper.accessor(row => row.last_name, {
			id: 'last_name',
			header: () => <span>{t('userData.lastName')}</span>,
			cell: info => info.getValue(),
			footer: info => info.column.id,
		}),
		columnHelper.accessor('email', {
			header: () => <span>{t('userData.email')}</span>,
			footer: info => info.column.id,
		}),
	]

	useEffect(() => {
		setIsFetchingData(true);
		app.restStore.getUserFriends(props.id).then((res) => {
			setData(res.data.data);
		}).catch((error) => {
			setFetchingError(error);
		}).finally(() => {
			setIsFetchingData(false);			
		});
	}, []);

	const friendsTabGroup = (
		<>
			<div className="bg0-primary flex">
				<div className={`px-3 py-3 cursor-pointer text-center rounded-t-xl ${currentTabIndex === 0 && "bg-mainlight"}`} onClick={(evt) => { setCurrentTabIndex(0); }}>{t('friends.accepted')}</div>
				<div className={`px-3 py-3 cursor-pointer text-center rounded-t-xl ${currentTabIndex === 1 && "bg-mainlight"}`} onClick={(evt) => { setCurrentTabIndex(1); }}>{t('friends.pending')}</div>
				<div className={`px-3 py-3 cursor-pointer text-center rounded-t-xl ${currentTabIndex === 2 && "bg-mainlight"}`} onClick={(evt) => { setCurrentTabIndex(2); }}>{t('friends.sent')}</div>
			</div>
			<div className="bg-white hfull">
				{ currentTabIndex === 0 && data && <DTable columns={columns} rows={data.friendships_in_accepted} actionsEnabled={false} /> }
				{ currentTabIndex === 1 && data && <DTable columns={columns} rows={data.friendships_in_pending} actionsEnabled={false} /> }
				{ currentTabIndex === 2 && data && <DTable columns={columns} rows={data.friendships_in_pending_received} actionsEnabled={false} /> }
			</div>
		</>
	)

	return (
		<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-2 px-6">
			<div className="w-full">
				{ isFetchingData && <Spinner /> }
				{ !isFetchingData && !fetchingError && friendsTabGroup }
				{ fetchingError && <ErrorMessage text={fetchingError} />}
			</div>
		</div>
    );
});

export default InstitutionUserFriendsTab;
