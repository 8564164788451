import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useState, useRef } from "react";
import { useStores } from '../../stores/store';
import { Link } from 'react-router-dom';
import { isValidEmail, isValidNin } from '../../utils/utils'
import PasswordField from '../../components/forms/passwordField';
import ErrorMessage from '../../components/other/ErrorMessage';

const RegisterInstitution = observer(() => {
	const { app } = useStores();
	const { t } = useTranslation();
	const history = useHistory();

	const [isLoading, setIsLoading] = useState(false);
	const [loadingError, setLoadingError] = useState(null);
	const currentPassword = useRef(null);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = (data) => {
		if (currentPassword.current == null)
			return;

		data['password'] = currentPassword.current;

		setIsLoading(true);
		setLoadingError(null);
		app.restStore.registerInstitution(data).then(() => {
			history.push('/login');
		}).catch((error) => {
			setLoadingError(error);
		}).finally(() => {
			setIsLoading(false);
		});
	}

	const passwordChange = (value, isGood) => {
		if (!isGood)
			currentPassword.current = null;
		else
			currentPassword.current = value.password;
	}

	return (
		<div className='h-full'>
			<div className='absolute top-0 bottom-0 left-0 right-0 bg-white flex opacity-25'></div>
			<div className='container mx-auto flex justify-center items-center'>
				<div className='bg-main rounded-md items-center h-3/4 mx-2 relative p-6 my-4'>
					<div className='w-full flex items-center justify-center'>
						<img alt="logo" src="/images/logo.png" />
					</div>
					<div className="container mx-auto flex flex-col gap-6 pt-2 px-2 justify-center">
						<form className="dit-form" onSubmit={handleSubmit(onSubmit)}>
							<div className='flex flex-col md:flex-col'>
								<label>{t('userData.name')}
									<input {...register('name', { required: true, })} className='w-full pl-2 py-1 focus:border-secondary'/>
									{errors.name && <ErrorMessage>{ t('nameRequired') }</ErrorMessage>}
								</label>
								<label>{t('userData.email')}
									<input {...register('email', { required: true, validate: isValidEmail })} className='w-full pl-2 py-1 focus:border-secondary'/>
									{errors.email && <ErrorMessage>{ t('emailRequired') }</ErrorMessage>}
								</label>
								<label>{t('userData.nin')}
									<input {...register('p_iva_cf', { required: true, validate: isValidNin })} className='w-full pl-2 py-1 focus:border-secondary'/>
									{errors.surname && <ErrorMessage>{ t('ninRequired') }</ErrorMessage>}
								</label>
							</div>
							<PasswordField onChange={passwordChange} />
							{loadingError && <ErrorMessage>{ t('errors.loadingData') }</ErrorMessage> }
							<input type="submit" disabled={isLoading} value={t('userActions.signup').toString()} className='cursor-pointer btn bg-secondary block my-4 mx-auto w-full text-white' />
						</form>
						<div className='text-center'>
							{ t('loginQuestion') }
							
							<Link
								to={{
									pathname: '/login',
								}}
								className='cursor-pointer block my-4 mx-auto w-full text-secondary font-bold'>
									{t('userActions.login')}
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});

export default RegisterInstitution;