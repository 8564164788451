import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../stores/store";
import { BaseModal } from '../../../components/modals/baseModal';
import { Link } from 'react-router-dom';
import DTable from "../../../components/tables/DTable"

import { createColumnHelper } from '@tanstack/react-table'
import { Institution } from "../../../models/institution";
import { Spinner } from "../../../components/spinners/spinner";
import ErrorMessage from "../../../components/other/ErrorMessage";
import { humanReadableTopic } from "../../../utils/utils";
import { CSVLink } from "react-csv";

const InstitutionListPage = observer(() => {
	const { app, auth } = useStores();
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [fetchingError, setFetchingError] = useState(null);
	const [deleteAsk, setDeleteAsk] = useState(null);

	const columnHelper = createColumnHelper<Institution>()

	const columns = [
		columnHelper.accessor('name', {
			header: () => <span>{t('activities.title')}</span>,
			cell: info => info.getValue(),
			footer: info => info.column.id,
		}),
		columnHelper.accessor('email', {
			header: () => <span>{t('activities.email')}</span>,
			footer: info => info.column.id,
		}),
		columnHelper.accessor('topic', {
			header: () => <span>{t('activities.topic')}</span>,
			cell: info => humanReadableTopic(info.getValue()),
			footer: info => info.column.id,
		}),
		columnHelper.accessor(row => `${row.Activities.length}`, {
			id: 'activities',
			header: () => <span>{t('activities.activities')}</span>,
			footer: info => info.column.id,
		}),
	]

	const refresh = () => {
		setIsFetchingData(true);
		app.restStore.getAdminInstitutions().then((res) => {
			setData(res.data.data);
		}).catch((error) => {
			setFetchingError(error);
		}).finally(() => {
			setIsFetchingData(false);			
		});
	}

	useEffect(() => {
		refresh();
    }, []);

	const deleteItem = (id: string) => {
		setDeleteAsk(id);
	}

	const doDeleteItem = (id: string) => {
		app.restStore.deleteAdminInstitution(id).then((res) => {
			refresh();
			setDeleteAsk(null);
		}).catch((error) => {
		}).finally(() => {
		});
	}

	const deleteModal = (
		<BaseModal
			isOpen={deleteAsk != null}
			onClose={() => {setDeleteAsk(null)}}
			onCta={() => { doDeleteItem(deleteAsk) }}
			ctaText={t('misc.yes')}
			closeText={t('misc.no')}
			title={t('userActions.delete')}
		>
			<div>
				<div className='font-semibold text-4xl text-black mb-4'>{t('userActions.delete')}</div>
				<div className='px-4 sm:px-6 pb-4 sm:pb-6 text-black'>{t('userActions.confirmDelete')}</div>
			</div>
		</BaseModal>
	)

	const filterCSV = (data) => {
		return data.map(({name, email, topic, p_iva_cf, region}) => ({name, email, topic, p_iva_cf, region}));
	}

    return (
        <div className="container mx-auto flex flex-col md:flex-row gap-6 pt-6 px-2">
            <div className="w-full md:w-11/12">
                <h1 className="text-4xl">{t('institutions.list')}</h1>
				{ isFetchingData && <Spinner /> }
				{ !isFetchingData && !fetchingError && (
					<div>
						<CSVLink filename={"institutions-export.csv"} className='cursor-pointer p-1 bg-secondary text-white rounded-full float-right mb-2 ml-1' data={filterCSV(data)}>CSV</CSVLink>
						<Link className='cursor-pointer p-1 bg-secondary text-white rounded-full float-right mb-2' to={{ pathname: `/admin/institutions/new`}}>{t('userActions.addNew')}</Link>
						<DTable columns={columns} rows={data} actionsEnabled={true} delete={deleteItem} />
					</div>
				)}
				{ fetchingError && <ErrorMessage text={fetchingError} />}
            </div>

			{ deleteModal }
        </div>
    );
});

export default InstitutionListPage;