import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../stores/store";
import DTable from "../../../../components/tables/DTable"

import { createColumnHelper } from '@tanstack/react-table'
import { useEffect, useState } from "react";
import { Spinner } from "../../../../components/spinners/spinner";
import ErrorMessage from "../../../../components/other/ErrorMessage";
import { Activity } from "../../../../models/activity";
import { humanReadableTopic } from "../../../../utils/utils";
import InstitutionActivityEditPanel from "./institutionActivityEditPanel";
import { BaseModal } from "../../../../components/modals/baseModal";

const InstitutionActivityListTab = observer((props: any) => {
	const { app } = useStores();
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [fetchingError, setFetchingError] = useState(null);
	const [addActivity, setAddActivity] = useState(false);
	const [deleteAsk, setDeleteAsk] = useState(null);
	const [currentActivityId, setCurrentActivityId] = useState(null);

	const columnHelper = createColumnHelper<Activity>()

	const columns = [
		columnHelper.accessor('title', {
			header: () => <span>{t('activities.title')}</span>,
			cell: info => info.getValue(),
			footer: info => info.column.id,
		}),
		columnHelper.accessor('topic', {
			header: () => <span>{t('activities.topic')}</span>,
			cell: info => humanReadableTopic(info.getValue()),
			footer: info => info.column.id,
		}),
		columnHelper.accessor('description', {
			header: () => <span>{t('activities.description')}</span>,
			cell: info => info.getValue().substring(0, 150) + "...",
			footer: info => info.column.id,
		}),
		columnHelper.accessor('Trend.total_support', {
			header: () => <span>{t('activities.totalSupports')}</span>,
			cell: info => info.getValue(),
			footer: info => info.column.id,
		}),
		columnHelper.accessor('Trend.total_donations', {
			header: () => <span>{t('activities.totalDonations')}</span>,
			cell: info => info.getValue(),
			footer: info => info.column.id,
		}),
	]

	useEffect(() => {
		refresh();
	}, []);

	const refresh = () => {
		setIsFetchingData(true);
		app.restStore.getInstitutionActivities(props.institutionId).then((res) => {
			setData(res.data.data.items);
		}).catch((error) => {
			setFetchingError(error);
		}).finally(() => {
			setIsFetchingData(false);			
		});
	}

	const onCloseAdd = () => {
		setAddActivity(false);
		refresh();
	}

	const deleteItem = (id: string) => {
		setDeleteAsk(id);
	}

	const doDeleteItem = (id: string) => {
		app.restStore.deleteActivity(id).then((res) => {
				refresh();
				setDeleteAsk(null);
		}).catch((error) => {
		}).finally(() => {
		});
	}

	const deleteModal = (
		<BaseModal
			isOpen={deleteAsk != null}
			onClose={() => {setDeleteAsk(null)}}
			onCta={() => { doDeleteItem(deleteAsk) }}
			ctaText={t('misc.yes')}
			closeText={t('misc.no')}
			title={t('userActions.delete')}
		>
			<div>
				<div className='font-semibold text-4xl text-black mb-4'>{t('userActions.delete')}</div>
				<div className='px-4 sm:px-6 pb-4 sm:pb-6 text-black'>{t('userActions.confirmDelete')}</div>
			</div>
		</BaseModal>
	)

	const modifyItem = (itemId) => {
		setCurrentActivityId(itemId);
		setAddActivity(true);
	}

    return (
        <div className="container mx-auto flex flex-col md:flex-row gap-6 pt-2 px-6 min-h-full">
            <div className="w-full">
				{ !addActivity && isFetchingData && <Spinner /> }
				{ !addActivity && !isFetchingData && !fetchingError && (
					<div>
						<button className="cursor-pointer p-1 bg-secondary text-white rounded-full float-right mb-2" onClick={() => setAddActivity(true)}>{t('userActions.addNew')}</button>
						<DTable columns={columns} rows={data} actionsEnabled={true} modifyAction={modifyItem} delete={deleteItem} /> 
					</div>
				)}
				{ !addActivity && fetchingError && <ErrorMessage text={fetchingError} />}
				{ addActivity && <InstitutionActivityEditPanel institutionId={props.institutionId} activityId={currentActivityId} close={onCloseAdd} />}

				{ deleteModal }
            </div>
        </div>
    );
});

export default InstitutionActivityListTab;
