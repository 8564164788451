import React from 'react';
import ReactDOM from 'react-dom';
import { config } from './config';

//Style
import 'tailwindcss/tailwind.css';
import './index.css';

//Stores
import { Stores, StoresContext } from './stores/contexts';
import { RestStore } from './stores/restStore';

// Translation
import './i18n/i18n.ts';

//Routing
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';

import './fonts/impact.ttf';
import { AppStore, AuthStore } from './stores/netStore';
import { InstitutionDashboardPage } from './pages/institution/dashboard/institutionDashboardPage';
import NavWrapper from './components/sidebars/sidebarWrapper';
import UserEdit from './pages/admin/users/edit/userEditPage';
import InstitutionListPage from './pages/admin/institutions/institutionListPage';
import InstitutionEditPage from './pages/admin/institutions/edit/institutionEditPage';
import AdminProfilePage from './pages/admin/profile/adminProfilePage';
import AdminUserListPage from './pages/admin/users/userListPage';
import DonorListPage from './pages/institution/donors/donorListPage';
import { Login } from './pages/login/login';
import { Page404 } from './pages/page404';
import { AdminDashboardPage } from './pages/admin/dashboard/adminDashboardPage';
import RegisterInstitution from './pages/login/registerInstitution';
import { ResetPassword } from './pages/login/resetPassword';
import ActivityListPage from './pages/institution/activities/activityListPage';
import { observer } from 'mobx-react-lite';
import { Role } from './models/role';
import InstitutionProfilePage from './pages/institution/profile/institutionProfilePage';
import ActivityEditPage from './pages/institution/activities/activityEditPanel';
import InstitutionNewPage from './pages/admin/institutions/institutionNewPage';
import DonorDetailsPage from './pages/institution/donors/details/donorDetailsPage';
import AdminUserNew from './pages/admin/users/new/userNew';

const authStore = new AuthStore();
const restStore = new RestStore(authStore);
let stores: Stores = {
	app: new AppStore(authStore, restStore),
	auth: authStore,
	rest: restStore,
};

if (config.env === 'production') {
	console.log = function no_console() { };
}

export const InstitutionRoute = observer((props: any) => {  
	const { children, ...rest } = props;
  
	return (
		<Route {...rest} render={matchedProps => (
			stores.auth.profile && stores.auth.profile.role === Role.institution ?
				React.cloneElement(<NavWrapper>{children}</NavWrapper>, matchedProps) 
			:
				<Redirect to="/login"/>
		)}/>
	);
});

export const AdminRoute = observer((props: any) => {  
	const { children, ...rest } = props;

	return (
		<Route {...rest} render={matchedProps => (
			stores.auth.profile && stores.auth.profile.role === Role.admin ?
				React.cloneElement(<NavWrapper>{children}</NavWrapper>, matchedProps) 
			:
				<Redirect to="/login"/>
		)}/>
	);
});


ReactDOM.render(
	<React.StrictMode>
		<StoresContext.Provider value={stores}>

			<Router>
				<Switch>

					{/* Public routes */}
					<Route exact path='/'>
						<Redirect to="/login" />
					</Route>
					<Route exact path='/login/'>
						<Login />
					</Route>
					<Route exact path='/signup/'>
						<RegisterInstitution />
					</Route>
					<Route exact path='/reset_password/'>
						<ResetPassword />
					</Route>
					
					{/* Institution routes */}
					<InstitutionRoute exact path='/institution/dashboard/'>
						<InstitutionDashboardPage />
					</InstitutionRoute>
					<InstitutionRoute exact path='/institution/profile'>
						<InstitutionProfilePage />
					</InstitutionRoute>
					<InstitutionRoute exact path='/institution/donors/'>
						<DonorListPage />
					</InstitutionRoute>
					<InstitutionRoute exact path='/institution/donors/:donorId'>
						<DonorDetailsPage />
					</InstitutionRoute>
					<InstitutionRoute exact path='/institution/activities/'>
						<ActivityListPage />
					</InstitutionRoute>
					<InstitutionRoute exact path='/institution/activities/:activityId'>
						<ActivityEditPage />
					</InstitutionRoute>

					{/* Admin routes */}
					<AdminRoute exact path='/admin/dashboard/'>
						<AdminDashboardPage />
					</AdminRoute>
					<AdminRoute exact path='/admin/users/'>
						<AdminUserListPage />
					</AdminRoute>
					<AdminRoute exact path='/admin/users/new'>
						<AdminUserNew />
					</AdminRoute>
					<AdminRoute exact path='/admin/users/:userId'>
						<UserEdit />
					</AdminRoute>
					<AdminRoute exact path='/admin/profile'>
						<AdminProfilePage />
					</AdminRoute>
					<AdminRoute exact path='/admin/institutions/'>
						<InstitutionListPage />
					</AdminRoute>
					<AdminRoute exact path='/admin/institutions/new'>
						<InstitutionNewPage />
					</AdminRoute>
					<AdminRoute exact path='/admin/institutions/:institutionId'>
						<InstitutionEditPage />
					</AdminRoute>

					{/* Fallback route */}
					<Route path="*">
						<Page404 />
					</Route>
				</Switch>
			</Router>

		</StoresContext.Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();