import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, Link } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../stores/store";
import DTable from "../../../../components/tables/DTable"

import { createColumnHelper } from '@tanstack/react-table'
import { FaArrowLeft } from 'react-icons/fa';
import { Spinner } from "../../../../components/spinners/spinner";
import ErrorMessage from "../../../../components/other/ErrorMessage";
import { Donation } from "../../../../models/donation";
import { humanReadableDonationType } from "../../../../utils/utils";

const DonorDonationListTab = observer((props: any) => {
	const { app } = useStores();
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [fetchingError, setFetchingError] = useState(null);

	const columnHelper = createColumnHelper<Donation>()

	const columns = [
		columnHelper.accessor(row => row.Activity, {
			id: 'title',
			header: () => <span>{t('activities.activities')}</span>,
			cell: info => info.getValue() == null ? "" : info.getValue().title,
			footer: info => info.column.id,
		}),
		columnHelper.accessor('type', {
			header: () => <span>{t('donors.type')}</span>,
			cell: info => humanReadableDonationType(info.getValue()),
			footer: info => info.column.id,
		}),
		columnHelper.accessor('quantity', {
			header: () => <span>{t('donors.quantity')}</span>,
			cell: info => info.getValue() + " €",
			footer: info => info.column.id,
		}),
		columnHelper.accessor('when_payment', {
			header: () => <span>{t('donors.date')}</span>,
			cell: info => { return (new Date(info.getValue()).toLocaleDateString('it')) },
			footer: info => info.column.id,
		}),
	]

	useEffect(() => {
		setIsFetchingData(true);
		app.restStore.getDonations(props.id).then((res) => {
			console.log(res.data.data.history);
			setData(res.data.data.history);
		}).catch((error) => {
			setFetchingError(error);
		}).finally(() => {
			setIsFetchingData(false);			
		});
    }, []);

	return (
		<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-6 px-6">
			<div className="w-full">				{ isFetchingData && <Spinner /> }
				{ !isFetchingData && !fetchingError && <DTable columns={columns} rows={data} actionsEnabled={false} /> }
				{ fetchingError && <ErrorMessage text={fetchingError} />}
			</div>
        </div>
    );
});

export default DonorDonationListTab;
