import React from "react";
import Sidebar from "react-sidebar";
import { useStores } from "../../stores/store";
import InstitutionNav from "./institutionNavbar";
import AdminNav from "./adminNavbar";

interface NavWrapperProps {}

const NavWrapper: React.FC<NavWrapperProps> = ({ children }) => {

	const { auth } = useStores();

	return (
		<Sidebar
			sidebar={ auth.profile.role === "admin" ? <AdminNav /> : <InstitutionNav />}
			open={true}
			docked={true}
			transitions={false}	
			styles={{ sidebar: { background: "#AB98CA" } }}
			shadow={false}
		>
			<div className="main">
			{children}
			</div>
		</Sidebar>
	);
};

export default NavWrapper;
