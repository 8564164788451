import React from 'react';
import { ResponsiveContainer, LineChart, Line, LabelList, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import { getMonthShortName } from '../../utils/utils';

const ValueContent = (props) => {
	return (
		<>
			<div className='px-3 pb-3'>
				<h4 className="text-4xl md:text-4xl font-bold text-left" style={{wordBreak: 'break-word'}}>{ props.value }</h4>

			</div>
			<div className='px-3 pb-3'>
				<p className="text-xs">
					{ props.description }
				</p>
			</div>
		</>
	)
}

const ChartContent = (props) => {
	var parsedData = [];

	Object.keys(props.value).forEach(function(key, index) {
		parsedData.push({'key': getMonthShortName(key), 'value': props.value[key]})
	});

	return (
		<>
			<ResponsiveContainer width={'100%'} height={'100%'}>
				<LineChart margin={{ top: 0, left: -25, right: 20, bottom: 0 }}
					data={parsedData}
				>
					<XAxis type="category" dataKey="key" tick={{fontSize: 12}} />
					<YAxis type="number" dataKey="value"  tick={{fontSize: 12}} />
					<Tooltip />
					<CartesianGrid stroke="#EAEEF3" />
					<Line type="monotone" dataKey="value" stroke="#AB98CA" yAxisId={0}> <LabelList position="bottom" offset={10} dataKey="name" /></Line>
				</LineChart>
			</ResponsiveContainer>
		</>
	)
}

const DashboardCard: React.FC<{ title: string, value: any, description: string, type: string }> = props => {

	return (
		<div
			className='w-20 h-20 md:w-15 md:h-10 bg-main border-2 bg-white shadow-xl border-main relative flex flex-col items-start justify-between rounded-md overflow-hidden mr-4'>
			<div className='w-full pointer-events-none flex items-start justify-start px-3 py-3 font-bold'>
				{ props.title }
			</div>

			{ props.type === "value" && <ValueContent value={props.value} description={props.description} />}
			{ props.type === "chart" && <ChartContent value={props.value} />}
		</div>
  );
};

export default DashboardCard;
