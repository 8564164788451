import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { FaList, FaPen, FaTrash } from 'react-icons/fa';
import {
	flexRender,
	useReactTable,
	getCoreRowModel
} from '@tanstack/react-table'

const DTable = (props: { columns, rows, actionsEnabled, modifyAction?, hasModify?, delete?, detailsEnabled? }) => {
	const [data, setData] = useState(() => [...props.rows]);
	const [columns, setColumns] = useState(() => [...props.columns])
	const { t } = useTranslation();

	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
	})

	return (
		<table className="w-full text-center mb-2">
			<thead className="bg-mainlight">
				{table.getHeaderGroups().map(headerGroup => (
					<tr key={headerGroup.id}>
						{headerGroup.headers.map(header => (
							<th className="px-2 py-2" key={header.id}>
								{header.isPlaceholder ? null
								: flexRender(
									header.column.columnDef.header,
									header.getContext()
								)}
							</th>
						))}
						{props.actionsEnabled && <th className="px-2 py-2">{t('actions')}</th> } 
						{props.detailsEnabled && <th className="px-2 py-2">{t('details')}</th> } 
					</tr>
				))}
			</thead>
			<tbody>
				{table.getRowModel().rows.map(row => (
					<tr className="even:bg-white odd:bg-gray" key={row.id}>
						{row.getVisibleCells().map(cell => (
							<td className="px-2 py-2" key={cell.id}>
								{flexRender(cell.column.columnDef.cell, cell.getContext())}
							</td>
						))}
						{ props.actionsEnabled &&
							<td>
								<div className="flex gap-1 px-2 py-2 justify-center">
									{ props.modifyAction == undefined ?
										((props.hasModify != undefined && props.hasModify == false) ? null :
											<Link to={{ pathname: `${data[row.id].id}/`}}><FaPen color="AB98CA" /></Link>)
									:
										<button
											onClick={() => props.modifyAction(data[row.id].id)}
										><FaPen color="AB98CA" /></button>
									}
									<button
										onClick={() => props.delete(data[row.id].id)}><FaTrash color="red" /></button>
								</div>
							</td>
						}
						{ props.detailsEnabled &&
							<td>
								<div className="flex gap-1 px-2 py-2 justify-center">
									<Link to={{ pathname: `${data[row.id].id}/`}}><FaList color="AB98CA" /></Link>
								</div>
							</td>
						}
					</tr>
				))}
			</tbody>
		</table>
	)
}

export default DTable;
