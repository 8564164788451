import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { useStores} from '../../../stores/store';
import ErrorMessage from '../../../components/other/ErrorMessage';
import { humanReadableTopic, IMAGE_DEFAULT_NAME, isBiggerThan, isValidEmail, isValidNin, MEDIA_IMAGE_CAROUSEL, MEDIA_IMAGE_LOGO, regions } from '../../../utils/utils';
import ImageCardGrid from '../../../components/cards/imageCardGrid';
import { Topic } from '../../../models/topic';
import { Spinner } from '../../../components/spinners/spinner';
import { format } from "date-fns";

const InstitutionProfilePage = observer((props: any) => {
	const { app, auth } = useStores();
	const { t } = useTranslation();
	const history = useHistory();
	const [data, setData] = useState(null);
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [fetchingError, setFetchingError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [loadingError, setLoadingError] = useState(null);
	const [sizeError, setSizeError] = useState(false);
	const logoFileLoadRef = useRef(null);
	const carouselFileLoadRef = useRef(null);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	const refresh = () => {
		setIsFetchingData(true);
		app.restStore.getAdminInstitution(auth.profile.id).then((res) => {
			setData(res.data.data);
			reset(res.data.data);
		}).catch((error) => {
			setFetchingError(error);
		}).finally(() => {
			setIsFetchingData(false);			
		});
	}

	useEffect(() => {
		refresh();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSubmit = (data) => {
		setIsLoading(true);
		setLoadingError(null);
		app.restStore.addUpdateInstitutionUser(data).then(() => {
		}).catch((error) => {
			setLoadingError(error);
		}).finally(() => {
			setIsLoading(false);
			history.push("/institution/dashboard/")
		});
	}

	const loadLogoImage = (event) => {
		event.preventDefault();
		logoFileLoadRef.current.click();
	}

	const loadCarouselImage = (event) => {
		event.preventDefault();
		carouselFileLoadRef.current.click();
	}

	const logoFileUploadHandler = (event) => {
		event.preventDefault();

		var file = event.target.files[0];
		let fileSize = event.target.files[0].size;

		if (isBiggerThan(fileSize, 1)) {
			setSizeError(true);
			return;
		}

		var reader = new FileReader();
		reader.onload = function(event) {
			addLogoMedia(event.target.result);
		};
	  
		reader.readAsDataURL(file);
	};

	const carouselFileUploadHandler = (event) => {
		event.preventDefault();

		var file = event.target.files[0];
		let fileSize = event.target.files[0].size;

		if (isBiggerThan(fileSize, 1)) {
			setSizeError(true);
			return;
		}

		var reader = new FileReader();
		reader.onload = function(event) {
			addCarouselMedia(event.target.result);
		};
	  
		reader.readAsDataURL(file);
	};

	const stripeOnboard = () => {
		app.restStore.addStripeOnboarding().then((res) => {
			console.log(res);
			window.open(res.data.data);
		}).catch((error) => {
		}).finally(() => {
		});
	}

	const activateSubscription = () => {
		app.restStore.addSubscription().then((res) => {
			console.log(res.data);
			window.open(res.data.data.url);
		}).catch((error) => {
		}).finally(() => {
		});
	}

	const addLogoMedia = (imageData) => {
		app.restStore.addMedia(MEDIA_IMAGE_LOGO, IMAGE_DEFAULT_NAME, imageData).then((res) => {
			let logo = res.data.data.id;

			app.restStore.associateMediaToInstitution(true, auth.profile.id, logo).then((res) => {
				refresh();
			}).catch((error) => {
			}).finally(() => {
			});
		}).catch((error) => {
		}).finally(() => {
		});
	}

	const addCarouselMedia = (imageData) => {
		app.restStore.addMedia(MEDIA_IMAGE_CAROUSEL, IMAGE_DEFAULT_NAME, imageData).then((res) => {
			let carousel_imgs = [res.data.data.id];

			app.restStore.associateMediaToInstitution(false, auth.profile.id, carousel_imgs).then((res) => {
				refresh();
			}).catch((error) => {
			}).finally(() => {
			});
	
		}).catch((error) => {
		}).finally(() => {
		});
	}

	const deleteMedia = (id) => {
		app.restStore.deleteMedia(id).then((res) => {
			refresh();
		}).catch((error) => {
		}).finally(() => {
		});
	}

	const panel = (
		<>
			<div className='flex justify-between'>
				<div className='flex justify-start items-start gap-1'>
					<h1 className="text-4xl mb-2">{t('userData.myProfile')}</h1>
					{ (data?.is_active && !data?.trial_expiration) && (<label className='bg-green-300 rounded-full p-1'>{t('userData.subscriptionActive')}</label>) }
					{ (data?.is_active && data?.trial_expiration) && (<label className='cursor-pointer bg-danger rounded-full p-1'>{t('userData.subscriptionTrial')} { format(new Date(data.trial_expiration), 'dd/MM/yyyy') }</label>) }
					{ !data?.is_active && (<label onClick={activateSubscription} className='cursor-pointer bg-red-300 rounded-full p-1'>{t('userData.subscriptionNotActive')}</label>) }
				</div>
				<div className='flex justify-start items-start gap-1'>
					{ !data?.is_onboarding && (<button
						onClick={stripeOnboard}
						className='cursor-pointer p-1 bg-secondary text-white rounded-full'
						>{t('userActions.stripeOnboard')}</button>
					)}
				</div>
			</div>
			<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-4 px-6 justify-center bg-white">
				<form className="dit-form" onSubmit={handleSubmit(onSubmit)}>
					<div className='flex flex-col md:flex-row md:gap-4'>
						<label>{t('userData.name')}
							<input {...register('name', { required: true })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
							{errors.name && <ErrorMessage>{ t('errors.nameRequired') }</ErrorMessage>}
						</label>
						<label>{t('userData.nin')}
							<input {...register('p_iva_cf', { required: true, validate: isValidNin })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
							{errors.email && <ErrorMessage>{ t('errors.ninRequired') }</ErrorMessage>}
						</label>
					</div>
					<div className='flex flex-col md:flex-row md:gap-4'>
						<label>{t('userData.email')}
							<input {...register('email', { required: true, validate: isValidEmail })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
							{errors.email && <ErrorMessage>{ t('errors.emailRequired') }</ErrorMessage>}
						</label>

						<label>{t('userData.region')}
							<select {...register('region', { required: true })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'>
								{ Object.keys(regions).map(key => (
									<option key={key} value={regions[key]}>
										{regions[key]}
									</option>
								))}
							</select>
							{errors.region && <ErrorMessage>{ t('errors.regionRequired') }</ErrorMessage>}
						</label>
					</div>
					<div className='flex flex-col md:flex-row md:gap-4'>
						<label>{t('userData.topic')}
							<select {...register('topic', { required: true })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'>
								{Object.values(Topic).map(value =>
									<option value={value} key={value}>{humanReadableTopic(value)}</option>
								)}
							</select>
							{errors.topic && <ErrorMessage>{ t('errors.topicRequired') }</ErrorMessage>}
						</label>
						<label>{t('userData.payoff')}
							<input {...register('payoff')} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
						</label>
					</div>
					<label>{t('userData.description')}
						<textarea {...register('description')} className='w-full pl-2 py-1 mb-1 focus:border-secondary block'/>
					</label>
					
					<input type="file" name="fileLogo"
						ref={logoFileLoadRef} accept="image/*" hidden
						onChange={logoFileUploadHandler}
					/>
					<input type="file" name="carouselFile"
						ref={carouselFileLoadRef} accept="image/*" hidden
						onChange={carouselFileUploadHandler}
					/>

					<div>
						<div>
							<label>{t('misc.logo')}</label>
							<button
								onClick={loadLogoImage}
								className='cursor-pointer btn bg-secondary text-white'
							>{t('userActions.addLogo')}</button>
						</div>
						<div className='mb-2'>
							<ImageCardGrid images={data?.Media.filter((el) => el.type === "image_logo")} delete={deleteMedia} />
						</div>
					</div>

					<div>
						<div>
							<label>{t('misc.images')}</label>
							<button
								onClick={loadCarouselImage}
								className='cursor-pointer btn bg-secondary text-white'
							>{t('userActions.addImage')}</button>
						</div>
						<div className='mb-2'>
							<ImageCardGrid images={data?.Media.filter((el) => el.type === "image_carousel")} delete={deleteMedia} />
						</div>
					</div>

					{sizeError && <ErrorMessage>{ t('errors.fileIsTooBig') }</ErrorMessage> }
					{loadingError && <ErrorMessage>{ t('errors.loadingData') }</ErrorMessage> }
					<input type="submit" disabled={isLoading} value={t('misc.save').toString()} className='cursor-pointer btn bg-secondary block my-2 mx-auto float-right text-white' />
				</form>
			</div>
		</>
	);

	return (
		<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-6 px-2">
			<div className="w-full md:w-11/12">
				{ isFetchingData && <Spinner /> }
				{ !isFetchingData && !fetchingError && panel }
				{ fetchingError && <ErrorMessage text={fetchingError} />}
			</div>
        </div>
	);

});

export default InstitutionProfilePage;
