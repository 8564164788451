import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from 'react-router-dom';
import { useStores } from '../../../stores/store';
import ErrorMessage from '../../../components/other/ErrorMessage';
import { Topic } from '../../../models/topic';
import { humanReadableActivityType, humanReadableTopic, IMAGE_DEFAULT_NAME, isBiggerThan, MAX_FILE_SIZE, MEDIA_IMAGE_CAROUSEL } from '../../../utils/utils';
import { ActivityType } from '../../../models/activityType';
import { FaArrowLeft } from 'react-icons/fa';
import ImageCardGrid from '../../../components/cards/imageCardGrid';

const ActivityEditPanel = observer((props: any) => {
	const { app } = useStores();
	const { t } = useTranslation();
	const { activityId } = useParams();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [loadingError, setLoadingError] = useState(null);
	const [imageData, setImageData] = useState([]);
	const [remoteImageData, setRemoteImageData] = useState([]);
	const [sizeError, setSizeError] = useState(false);
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [fetchingError, setFetchingError] = useState(null);
	const [typeValue, setTypeValue] = useState("");
	const carouselFileLoadRef = useRef(null);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	useEffect(() => {
		if (activityId)
			refresh();
	}, []);

	const refresh = () => {
		setIsFetchingData(true);
		app.restStore.getActivity(activityId).then((res) => {
			setRemoteImageData(res.data.data.media.images)
			setTypeValue(res.data.data.activity.type)
			reset(res.data.data.activity);
		}).catch((error) => {
			setFetchingError(error);
		}).finally(() => {
			setIsFetchingData(false);
		});
	}

	const onSubmit = (data) => {
		setIsLoading(true);
		setLoadingError(null);

		if (data['result'] == false)
			data['result_number'] = undefined;

		app.restStore.addUpdateActivity(data).then(async (activityRes) => {
			let carousel_imgs = [];

			for (var i = 0; i < imageData.length; i++) {
				let myData = imageData[i];
				let res = await app.restStore.addMedia(MEDIA_IMAGE_CAROUSEL, IMAGE_DEFAULT_NAME, myData);
				carousel_imgs.push(res.data.data.id);
			}

			for (var i = 0; i < remoteImageData.length; i++) {
				let myData = remoteImageData[i];
				carousel_imgs.push(myData.id);
			}

			app.restStore.associateMediaToActivity(activityRes.data.data.id, carousel_imgs);
		}).catch((error) => {
			setLoadingError(error);
			console.log(error);
		}).finally(() => {
			setIsLoading(false);
			close();
		});
	}

	const carouselFileUploadHandler = (event) => {
		event.preventDefault();

		var file = event.target.files[0];
		let fileSize = event.target.files[0].size;

		if (isBiggerThan(fileSize, MAX_FILE_SIZE)) {
			setSizeError(true);
			return;
		}

		var reader = new FileReader();
		reader.onload = function(event) {
			addCarouselMedia(event.target.result);
		};
	  
		reader.readAsDataURL(file);
	};

	const addCarouselMedia = (newData) => {
		setImageData([...imageData, newData])
	}

	const loadCarouselImage = (event) => {
		event.preventDefault();
		carouselFileLoadRef.current.click();
	}

	const deleteMedia = (idx) => {
		setRemoteImageData(remoteImageData.filter((item) => idx !== item.id ))
	}

	const deleteLocalMedia = (idx) => {
		setImageData(imageData.filter((_, index) => idx !== index))
	}

	const close = () => {
		if (props.close)
		props.close();
	else
		history.goBack();
	}

	return (
		<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-2 px-2">
			<div className="w-full md:w-11/12">
				<span onClick={close} className="text-sm font-bold mb-2 flex flex-row gap-1 text-stone-400 align-middle cursor-pointer"><FaArrowLeft />{t('activities.backToList')}</span>
				<h1 className="text-4xl mb-2">{t('userActions.addNew')}</h1>
				<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-4 px-6 justify-center bg-white">
					<form className="dit-form" onSubmit={handleSubmit(onSubmit)}>
						<div className='flex flex-col md:flex-row md:gap-4'>
							<label>{t('activities.type')}
								<select {...register('type', { required: true })} onChange={(e) => setTypeValue(e.target.value)} className='w-full pl-2 py-1 mb-1 focus:border-secondary'>
									{Object.values(ActivityType).map(value =>
										<option value={value} key={value}>{humanReadableActivityType(value)}</option>
									)}
								</select>
								{errors.type && <ErrorMessage>{ t('typeRequired') }</ErrorMessage>}
							</label>
						</div>

						<div className='flex flex-col md:flex-row md:gap-4'>
							<label>{t('activities.title')}
								<input {...register('title', { required: true })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
								{errors.name && <ErrorMessage>{ t('titleRequired') }</ErrorMessage>}
							</label>
							<label>{t('activities.topic')}
								<select {...register('topic', { required: true })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'>
									{Object.values(Topic).map(value =>
										<option value={value} key={value}>{humanReadableTopic(value)}</option>
									)}
								</select>
								{errors.topic && <ErrorMessage>{ t('errors.topicRequired') }</ErrorMessage>}
							</label>
						</div>
						<div className='flex flex-col md:flex-row md:gap-4'>
							<label>{t('activities.description')}
								<textarea {...register('description')} className='w-full pl-2 py-1 mb-1 focus:border-secondary block'/>
							</label>
						</div>
						{ typeValue == ActivityType.campaign && (
							<div className='flex flex-col md:flex-row md:gap-4'>
								<label className='mr-2'>{t('activities.result')}
									<input type="checkbox" {...register('result')} className='w-full pl-2 py-1 mb-1 ml-1 focus:border-secondary block' />
								</label>
								<label>{t('activities.resultValue')}
									<div className='icon-input'>
										<span className='input-icon'>€</span>
										<input {...register('result_number')} type="number" className='w-full pl-2 py-1 mb-1 focus:border-secondary euro'/>
									</div>
								</label>
							</div>
						)}

						<input type="file" name="carouselFile"
							ref={carouselFileLoadRef} accept="image/*" hidden
							onChange={carouselFileUploadHandler}
						/>

						<div>
							<div>
								<label className='mb-2'>{t('misc.images')}</label>
								<button
									onClick={loadCarouselImage}
									className='cursor-pointer btn bg-secondary text-white mb-2'
								>{t('userActions.addImage')}</button>
							</div>
							<div className='mb-2'>
								<ImageCardGrid images={remoteImageData} localImages={imageData} delete={deleteMedia} localDelete={deleteLocalMedia} />
							</div>
						</div>

						{loadingError && <ErrorMessage>{ t('errors.loadingData') }</ErrorMessage> }
						<input type="submit" disabled={isLoading} value={t('misc.save').toString()} className='cursor-pointer btn bg-secondary block my-4 mx-auto text-white float-right' />
					</form>
				</div>
			</div>
		</div>
	);
});

export default ActivityEditPanel;
