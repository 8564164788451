import { observer } from 'mobx-react-lite';
import { useEffect, useState, } from 'react';
import { useStores } from '../../../stores/store';
import { useTranslation } from "react-i18next";
import { withTranslation } from 'react-i18next';
import DashboardCard from '../../../components/cards/dashboardCard';
import '../../../App.css';
import { Spinner } from '../../../components/spinners/spinner';
import ErrorMessage from '../../../components/other/ErrorMessage';

const DashboardData = (props) => {
	const { t } = useTranslation();

	return (
		<>
			<div className='flex flex-row relative mb-4'>
				<DashboardCard title={t('dashboard.supportsReceiver')} value={props.data.total_support} description="description" type="value" />
				<DashboardCard title={t('dashboard.donationsPerInstitution')} value={props.data.total_donations + " €"} description={t('dashboard.donationsPerInstitutions')} type="value" />
			</div>
			<div className='flex flex-row relative mb-4'>
				<DashboardCard title={t('dashboard.donorNumber')} value={props.data.total_donors} description={t('dashboard.donorNumberInstitution')} type="value" />
				<DashboardCard title={t('dashboard.donationsPerActivity')} value={props.data.donations_campaigns} description={t('dashboard.donationsPerActivityInstitution')} type="value" />
			</div>
		</>
	);
}

const InstitutionDashboard = observer(({ t }: any) => {
	const { app } = useStores();
	const [data, setData] = useState([]);
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [fetchingError, setFetchingError] = useState(null);

	useEffect(() => {
		setIsFetchingData(true);
		app.restStore.getInstitutionDashboardData().then((res) => {
			setData(res.data.data);
		}).catch((error) => {
			setFetchingError(error);
		}).finally(() => {
			setIsFetchingData(false);			
		});
    }, []);

	return (

		<div className='bg-main relative'>
			<div className='absolute top-0 left-0 w-screen z-0 pointer-events-none'>
				Dashboard
			</div>
			<div className='flex flex-col relative'>
				{ isFetchingData && <Spinner /> }
				{ !isFetchingData && !fetchingError && <DashboardData data={data} /> }
				{ fetchingError && <ErrorMessage text={fetchingError} />}
			</div>
		</div>
	);
});

export const InstitutionDashboardPage = withTranslation()(InstitutionDashboard);