import { AuthStore } from "./netStore";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { config } from "../config";

// Create `axios-cache-adapter` instance
const cache = setupCache({
	maxAge: 5 * 60 * 1000,
	exclude: {
		paths: [
		],
	},
	debug: false,
	invalidate: async (config, request) => {
		if (request.clearCacheEntry) {
		// @ts-ignore
		// 
		// @ts-ignore
		await config.store.removeItem(config.uuid);
		}
	},
});

export class RestStore {
	authStore: AuthStore;
	axios: any;

	constructor(authStore: AuthStore) {
		this.authStore = authStore;
		this.axios = axios.create({
			//adapter: cache.adapter,
		});

		// Add a response interceptor
		this.axios.interceptors.response.use(
			function(response) {
				// Any status code that lie within the range of 2xx cause this function to trigger
				// Do something with response data
				// console.log(
				//   `Cache hit?`,
				//   response.config.url,
				//   response.request.fromCache
				// );
				return response;
			},
			async function(error) {
				/* XXX: to be done */
				if (error.response.status === 500)
					return Promise.reject(error);
				if (error.response.status === 409)
					return Promise.reject();
			},
		);

		// Add a request interceptor
		this.axios.interceptors.request.use(
			function(config) {
				// 
				config.headers["Content-Type"] = "application/json";
				if (authStore.profile && authStore.token)
					config.headers["Authorization"] = `Bearer ${authStore.token}`;
				return config;
			},
			null,
			{ synchronous: true },
			function(error) {
				// Do something with request error
				return Promise.reject(error);
			}
		);

	}

	getAdminUsers = () => {
		return this.axios.get(`${config.getEndpointUri()}/users/`);
	}

	getAdminUser = (id: string) => {
		return this.axios.get(`${config.getEndpointUri()}/users/${id}/`);
	}

	addUpdateAdminUser = (data) => {
		if (data.id !== undefined)
			return this.axios.patch(`${config.getEndpointUri()}/users/${data.id}`, data);
		else
			return this.axios.post(`${config.getEndpointUri()}/users/`, data);
	}

	addUpdateInstitutionUser = (data) => {
		if (data.id !== undefined)
			return this.axios.patch(`${config.getEndpointUri()}/institutions/${data.id}`, data);
		else
			return this.axios.post(`${config.getEndpointUri()}/institutions/`, data);
	}

	deleteAdminUser = (id: string) => {
		return this.axios.delete(`${config.getEndpointUri()}/users/${id}`);
	}

	getUserDocuments = (id: string) => {
		return this.axios.get(`${config.getEndpointUri()}/users/${id}/documents`);
	}

	deleteUserDocuments = (userId: string, docId: string) => {
		return this.axios.delete(`${config.getEndpointUri()}/media/${docId}/`);
	}

	getUserFriends = (id: string) => {
		return this.axios.get(`${config.getEndpointUri()}/users/${id}/friendships`);
	}

	getUserDonations = (id: string) => {
		return this.axios.get(`${config.getEndpointUri()}/users/${id}/donations`);
	}

	getUserFollowing = (id: string) => {
		return this.axios.get(`${config.getEndpointUri()}/users/${id}/supports`);
	}

	getAdminInstitutions = () => {
		return this.axios.get(`${config.getEndpointUri()}/institutions/all`);
	}

	deleteAdminInstitution = (id: string) => {
		return this.axios.delete(`${config.getEndpointUri()}/institutions/${id}`);
	}

	getAdminInstitution = (id: string) => {
		return this.axios.get(`${config.getEndpointUri()}/institutions/${id}`);
	}

	getDonors = () => {
		return this.axios.get(`${config.getEndpointUri()}/institutions/donors/`);
	}

	getDonor = (id) => {
		return this.axios.get(`${config.getEndpointUri()}/users/${id}`);
	}

	getDonations = (id) => {
		return this.axios.get(`${config.getEndpointUri()}/institutions/donors/${id}`);
	}

	getInstitutionDashboardData = () => {
		return this.axios.get(`${config.getEndpointUri()}/dashboard/institution/`);
	}

	getAdminDashboardData = (region: string, year: number) => {
		return this.axios.post(`${config.getEndpointUri()}/dashboard/`, {'region': region, 'year': year});
	}

	registerInstitution = (data: any) => {
		return this.axios.post(`${config.getEndpointUri()}/auth/signup/`, {
			email: data.email,
			p_iva_cf: data.p_iva_cf,
			name: data.name,
			password: data.password
		});
	}

	otpRequest = (email: string) => {
		return this.axios.post(`${config.getEndpointUri()}/auth/otp/request?case=password`, {
			email: email
		});
	}

	otpCheck = (email: string, otp: string) => {
		return this.axios.post(`${config.getEndpointUri()}/auth/otp/login/`, {
			email: email,
			otp: otp
		});
	}

	addStripeOnboarding = () => {
		return this.axios.get(`${config.getEndpointUri()}/institutions/stripe-onboarding`);
	}

	addAdminStripeOnboarding = (id: string) => {
		return this.axios.get(`${config.getEndpointUri()}/institutions/stripe-onboarding/${id}`);
	}

	addSubscription = () => {
		return this.axios.post(`${config.getEndpointUri()}/payments/active-sub`);
	}

	changePassword = (password: string) => {
		return this.axios.put(`${config.getEndpointUri()}/me/password/`, {
			password: password,
		});
	}

	getInstitutionActivities = (id: string) => {
		return this.axios.get(`${config.getEndpointUri()}/institutions/${id}/activity`);
	}

	addUpdateActivity = (data) => {
		if (data.id !== undefined)
			return this.axios.patch(`${config.getEndpointUri()}/institutions/activity/${data.id}`, data);
		else
			return this.axios.post(`${config.getEndpointUri()}/institutions/activity/`, data);
	}

	addUpdateActivityForAdmin = (id, data) => {
		if (data.id !== undefined)
			return this.axios.patch(`${config.getEndpointUri()}/institutions/activity/${id}`, data);
		else
			return this.axios.post(`${config.getEndpointUri()}/institutions/activity/${id}`, data);
	}

	getActivity = (id) => {
		return this.axios.get(`${config.getEndpointUri()}/institutions/activity/${id}`);
	}

	deleteActivity = (id) => {
		return this.axios.delete(`${config.getEndpointUri()}/institutions/activity/${id}`);
	}

	addMedia = (type: string, title: string, data) => {
		return this.axios.post(`${config.getEndpointUri()}/media/`, {
			title: title,
			type: type,
			base64: data
		});
	}

	associateMediaToInstitution = (logo, id, imgs) => {
		if (logo) {
			return this.axios.patch(`${config.getEndpointUri()}/institutions/${id}`, {
				logo: imgs,
			});

		} else {
			return this.axios.patch(`${config.getEndpointUri()}/institutions/${id}`, {
				carousel_imgs: imgs,
			});
		}
	}

	associateMediaToActivity = (id, imgs) => {
		return this.axios.patch(`${config.getEndpointUri()}/institutions/activity/${id}`, {
			images: imgs,
		});
	}

	associateDoc = (id, doc) => {
		return this.axios.post(`${config.getEndpointUri()}/users/${id}/document`, {
			"profile_document": doc,
		});
	}

	deleteMedia = (id) => {
		return this.axios.delete(`${config.getEndpointUri()}/media/${id}`);
	}
}
