import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStores } from '../../../../../stores/store';
import { Spinner } from '../../../../../components/spinners/spinner';
import ErrorMessage from '../../../../../components/other/ErrorMessage';
import { isValidEmail } from '../../../../../utils/utils';

const NormalUserGeneralDataTab = observer((props: any) => {
	const { app } = useStores();
	const { t } = useTranslation();
	let { userId } = useParams();
	let history = useHistory();
	const [data, setData] = useState(null);
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [loadingError, setLoadingError] = useState(null);
	const [fetchingError, setFetchingError] = useState(null);
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	useEffect(() => {
		setIsFetchingData(true);
		app.restStore.getAdminUser(userId).then((res) => {
			setData(res.data.data);
			reset(res.data.data);
		}).catch((error) => {
			setFetchingError(error);
		}).finally(() => {
			setIsFetchingData(false);			
		});
	}, [userId]);

	const onSubmit = (data) => {
		setIsLoading(true);
		setLoadingError(null);
		app.restStore.addUpdateAdminUser(data).then(() => {
			history.goBack();
		}).catch((error) => {
			setLoadingError(error);
		}).finally(() => {
			setIsLoading(false);
		});
	}

	const validateEmail = email => {
		return isValidEmail(email);
	};

	return (
		<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-2 px-6 justify-center bg-white">
			<div className="w-full">
				{ isFetchingData && <Spinner /> }
				{ fetchingError && <ErrorMessage text={fetchingError} />}
				{ !isFetchingData && !fetchingError && (
					<form className="dit-form" onSubmit={handleSubmit(onSubmit)}>
						<div className='flex flex-col md:flex-row md:gap-4'>
							<label>{t('userData.firstName')}
								<input {...register('first_name', { required: true })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
								{errors.name && <ErrorMessage>{ t('nameRequired') }</ErrorMessage>}
							</label>
							<label>{t('userData.lastName')}
								<input {...register('last_name', { required: true })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
								{errors.surname && <ErrorMessage>{ t('surnameRequired') }</ErrorMessage>}
							</label>
						</div>
						<div className='flex flex-col md:flex-row md:gap-4'>
							<label>{t('userData.nickname')}
								<input {...register('nickname', { required: true })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
								{errors.email && <ErrorMessage>{ t('nicknameRequired') }</ErrorMessage>}
							</label>
							<label>{t('userData.email')}
								<input {...register('email', { required: true, validate: validateEmail })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
								{errors.email && <ErrorMessage>{ t('emailRequired') }</ErrorMessage>}
							</label>
						</div>
						<div className='flex flex-col md:flex-row md:gap-4'>
							<label>{t('userData.subscriptionDate')}
								<input {...register('subscriptionDate', { required: false, disabled: true })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
							</label>
							<label>{t('userData.userBadge')}
								<input {...register('userBadge')} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
							</label>
						</div>
						<div className='flex flex-col md:flex-row md:gap-4'>
							<label>{t('userData.address')}
								<input {...register('address')} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
							</label>
							<label>{t('userData.zipCode')}
								<input {...register('cap', )} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
							</label>
						</div>
						<div className='flex flex-col md:flex-row md:gap-4'>
							<label>{t('userData.city')}
								<input {...register('city')} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
							</label>
							<label>{t('userData.province')}
								<input {...register('province', )} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
							</label>
						</div>
						<div className='flex flex-col md:flex-row md:gap-4'>
							<label>{t('userData.role')}
								<input {...register('role', { disabled: true })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
							</label>
						</div>
						{loadingError && <ErrorMessage>{ t('errors.loadingData') }</ErrorMessage> }
						<input type="submit" disabled={isLoading} value={t('misc.save').toString()} className='cursor-pointer btn bg-secondary text-white block my-4 mx-auto float-right text-white' />
					</form>
				)}
			</div>
		</div>
	);
});

export default NormalUserGeneralDataTab;
