import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStores } from '../../../../../stores/store';
import ErrorMessage from '../../../../../components/other/ErrorMessage';
import { isValidEmail } from '../../../../../utils/utils';

const AdminUserGeneralDataTab = observer((props: any) => {
	const { app, auth } = useStores();
	const { t } = useTranslation();
	let history = useHistory();
	let { userId } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [loadingError, setLoadingError] = useState(null);
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	useEffect(() => {
		reset(props.data);
	}, [userId]);

	const onSubmit = (data) => {
		setIsLoading(true);
		setLoadingError(null);
		app.restStore.addUpdateAdminUser(data).then(() => {
			history.goBack();
		}).catch((error) => {
			setLoadingError(error);
		}).finally(() => {
			setIsLoading(false);
		});
	}

	return (
		<div className="container mx-auto flex flex-col md:flex-row gap-6 pt-2 px-6">
			<div className="w-full">
				<form className="dit-form" onSubmit={handleSubmit(onSubmit)}>
					<div className='flex flex-col md:flex-row md:gap-4'>
						<label>{t('userData.firstName')}
							<input {...register('first_name', { required: true })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
							{errors.name && <ErrorMessage>{ t('nameRequired') }</ErrorMessage>}
						</label>
						<label>{t('userData.lastName')}
							<input {...register('last_name', { required: true })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
							{errors.surname && <ErrorMessage>{ t('surnameRequired') }</ErrorMessage>}
						</label>
					</div>
					<div className='flex flex-col md:flex-row md:gap-4'>
						<label>{t('userData.email')}
							<input {...register('email', { required: true, validate: isValidEmail })} className='w-full pl-2 py-1 mb-1 focus:border-secondary'/>
							{errors.email && <ErrorMessage>{ t('emailRequired') }</ErrorMessage>}
						</label>
					</div>
					{loadingError && <ErrorMessage>{ t('errors.loadingData') }</ErrorMessage> }
					<input type="submit" disabled={isLoading} value={t('misc.save').toString()} className='cursor-pointer btn bg-secondary block my-4 mx-auto text-white float-right' />
				</form>
			</div>
		</div>
	);
});

export default AdminUserGeneralDataTab;
