import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useStores } from '../../stores/store';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Error = props => <div className='text-red-600 text-sm mt-1'>{props.children}</div>;

const emailValidation = email => {
	return (
		email.indexOf('@') !== -1 &&
		email.trim().length >= 3 &&
		email.substring(email.indexOf('@'), email.length).indexOf('.') !== -1
	);
};

export const Login = observer(props => {
	const { auth } = useStores();
	let history = useHistory();
	const { t } = useTranslation();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [passwordShown, setPasswordShown] = useState(false);
	const [errors, setErrors] = useState<{
		emailError: boolean;
		passwordError: boolean;
		authError: boolean;
	}>({
		emailError: false,
		passwordError: false,
		authError: false,
	});

	const goToDashboard = () => {
		if (auth.profile?.role === "admin")
			history.push('/admin/dashboard/');
		else
			history.push('/institution/dashboard/');

	}

	useEffect(() => {
		if (auth.profile) {
			if (history.location.state?.from)
				history.replace(history.location.state?.from);
			else
				goToDashboard();
		}
	}, []);

	const onChangeEmail = evt => {
		setEmail(evt.target.value);
		setErrors({ emailError: false, passwordError: false, authError: false });
	};

	const onChangePassword = evt => {
		setPassword(evt.target.value);
		setErrors({ emailError: false, passwordError: false, authError: false });
	};

	const submit = async evt => {
		evt.preventDefault();

		if (!emailValidation(email)) {
			setErrors(prevErrors => ({ ...prevErrors, emailError: true }));
			return;
		} else if (password === '') {
			setErrors(prevErrors => ({ ...prevErrors, passwordError: true }));
			return;
		}

		const res = await auth.login(email, password);
		if (!res || res.error) {
			setErrors(prevErrors => ({ ...prevErrors, authError: true }));
		} else {
			if (history.location.state?.from)
				history.push(history.location.state?.from);
			else
				goToDashboard();
		}
	};

	return (
		<div className='h-full'>
			<div className='absolute top-0 bottom-0 left-0 right-0 bg-white flex opacity-25'></div>
			<div className='container mx-auto flex justify-center items-center'>
				<div className='bg-main rounded-md items-center w-96 h-11/12 mx-2 relative p-6 my-8'>
					<div className='w-full flex items-center justify-center'>
						<img alt="logo" src="/images/logo.png" />
					</div>

					<div>
						<form method='post' className='dit-form mb-4'>
							<label>{t('userData.email')}
								<input
									type='email'
									id='fname'
									name='fname'
									placeholder='E-mail'
									className='w-72 pl-2 py-1 mb-1 focus:border-secondary bg-white'
									value={email}
									onChange={onChangeEmail}
								/>
								<Error>{errors.emailError && t('errors.insertValidMail')}</Error>
							</label>
							<div className="relative">
								<label>{t('userData.password')}
								
									<input
										type={passwordShown ? "text" : "password"}
										id='fname'
										name='fname'
										placeholder='Password'
										className='w-72 pl-2 py-1 mb-1 focus:border-secondary'
										value={password}
										onChange={onChangePassword}
									/>
									<Error>{errors.passwordError && t('errors.insertValidPassword')}</Error>
								</label>
								<div className="absolute inset-y-0 right-0 top-5 pr-3 flex items-center leading-5">
									{!passwordShown && <FaEye onClick={() => setPasswordShown(true) } className="text-xl cursor-pointer" color="gray" />}
									{passwordShown && <FaEyeSlash onClick={() => setPasswordShown(false) }className="text-xl cursor-pointer" color="gray" />}
								</div>
							</div>
							<Error>{errors.authError && t('errors.authError')}</Error>

							<input
								type='submit'
								onClick={submit}
								value={t('userActions.login').toString()}
								className='cursor-pointer btn bg-secondary block my-4 mx-auto w-full text-white'
							></input>
						</form>

						<div className='text-center mt-4'>
							<Link
								to={{
									pathname: '/reset_password',
								}}
								className='cursor-pointer block my-4 mx-auto w-full text-secondary font-bold'>
									{ t('lostPassword')}
							</Link>
							
							{ t('signupQuestion') }
							
							<Link
								to={{
									pathname: '/signup',
								}}
								className='cursor-pointer block my-4 mx-auto w-full text-secondary font-bold'>
									{t('userActions.signup')}
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});
